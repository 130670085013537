<!--
 * @Author: your name
 * @Date: 2021-10-10 12:07:11
 * @LastEditTime: 2022-10-16 16:50:32
 * @LastEditors: xiaoxuesheng 3832702@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /guanwang/src/pages/training/index.vue
-->
<template>
  <div class="box">
    <div class="boxContent">
      <Sidebar />
      <ListComp
        :page.sync="page"
        :isSearch="false"
        :limit.sync="limit"
        :list="list"
        :count="count"
        @getList="getList"
        title="标准查询"
        type= "09"
      />
    </div>
  </div>
</template>

<script>
import Sidebar from "../../components/sidebar"
import ListComp from "../../components/listQuery"

import { getDraftList } from "../../api/api.js"

export default {
  components: {
    Sidebar,
    ListComp,
  },
  data() {
    return {
      page: 1,
      list: [],
      count: 0,
      limit: 20,
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    /**
     * @description: 获取文章列表
     */
    async getList(form = {}) {
      try {
        const { page, limit } = this
        let formData = {}
        Object.keys(form).forEach(item => {
          if (form[item]) {
            formData[item] = form[item]
          }
        })
        const { list, totalCount } = await getDraftList({
          page,
          limit,
          ...formData
        })
        this.count = totalCount
        this.list = list
      } catch (e) {
        console.log(e)
        this.list = []
        this.count = 0
      }
    },
  },
}
</script>

<style scoped>
.boxContent {
  width: 14rem;
  display: flex;
  justify-content: space-between;
  height: 100%;
  min-height: 100vh;
  margin: 0 auto;
  min-width: 960px;
  padding-top: 0.2rem;
}
</style>
