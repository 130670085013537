<!--
 * @Author: your name
 * @Date: 2021-10-20 20:56:04
 * @LastEditTime: 2021-12-13 22:36:57
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /guanwang/src/pages/home/components/linkComp.vue
-->
<template>
<div class="linkComp">
    <ul class="linkHeader">
        <li class="linkHeaderTitle">
            <img src="../../../common/images/icon_links.png" alt="">
            友情链接
        </li>
        <li @click="clickHandler(1)" :class="{ active: activeIndex == 1 }">政府部门/组织</li>
        <li @click="clickHandler(2)" :class="{ active: activeIndex == 2 }">服务平台</li>
        <li @click="clickHandler(3)" :class="{ active: activeIndex == 3 }">标准出版社/物</li>
        <li @click="clickHandler(4)" :class="{ active: activeIndex == 4 }">国内标准化研究机构</li>
        <li @click="clickHandler(5)" :class="{ active: activeIndex == 5 }">国外标准化组织</li>
    </ul>
    <div v-if="activeIndex == -1" class="linkContent">
         <span @click="jumpHandler(item)" v-for="(item, index) in indexDetails.friendLinks" :key="index">{{ item.linkName }}</span>       
    </div>
    <div v-else-if="activeIndex == 1" class="linkContent">
        <span @click="jumpHandler(item)"  v-for="(item, index) in indexDetails.govementLinks" :key="index">{{ item.linkName }}</span>       
    </div>
    <div v-else-if="activeIndex == 2" class="linkContent">
        <span @click="jumpHandler(item)"  v-for="(item, index) in indexDetails.servicePlatLinks" :key="index">{{ item.linkName }}</span> 
    </div>
    <div v-else-if="activeIndex == 3" class="linkContent">
        <span @click="jumpHandler(item)"  v-for="(item, index) in indexDetails.publicationLinks" :key="index">{{ item.linkName }}</span> 
    </div>
    <div v-else-if="activeIndex == 4" class="linkContent">
        <span @click="jumpHandler(item)"  v-for="(item, index) in indexDetails.studyOrganLinks" :key="index">{{ item.linkName }}</span>   
    </div>
    <div v-else-if="activeIndex == 5" class="linkContent">
        <span @click="jumpHandler(item)"  v-for="(item, index) in indexDetails.tradCountryLinks" :key="index">{{ item.linkName }}</span> 
    </div>
</div>
</template>

<script>
export default {

    props: {
        indexDetails: {
            type: Object,
            default() {
                return {}
            }
        }
    },

    data() {
        return {
            activeIndex: 1, // 当前点击索引
        }
    },

    methods: {
        /**
         * @description: 点击时间
         * @param {Number} index 当前点击索引
         * @return {*}
         */        
        clickHandler(index) {
            this.activeIndex = index
        },

        jumpHandler(item) {
            window.open(item.url.indexOf('http') > -1 ? item.url: `http://${item.url}` )
        }
    }
}
</script>

<style scoped>

.linkComp {
    width: 100%;
    display: flex;
    flex-wrap: wrap;   
    justify-content: flex-start;
}

.linkHeader {
    width: 100%;
    height: .4rem;
    box-sizing: border-box;
    border-bottom: 5px solid #0071B7;
    font-size: .16rem;
    display: flex;
    font-weight: 400;
}

.linkHeader li {
    padding: 0 .2rem;
    line-height: .35rem;
    cursor:pointer;
}

.linkHeader li:hover {
    color: #0071B7;
    font-weight: bold;
    position: relative;
}

.linkHeader .linkHeaderTitle:hover {
    color: #fff;
}

.linkHeader .linkHeaderTitle {
    background: #0071B7;
    color: #fff;
    font-size: .18rem;
    font-weight: bold;
    line-height: .4rem;
    height: .4rem;
    display: flex;
    margin-right: .1rem;
    align-items: center;
}

.linkHeader .linkHeaderTitle img {
    width: .16rem;
    height: .16rem;
    margin-right: .1rem;
}

.active {
    /* color: #0071B7;
    font-weight: bold; */
    position: relative;
}

.active:after {
    content: " ";
    position: absolute;
    left: 50%;
    margin-left: -0.1rem;
    bottom: -0.13rem;
    width: 0;
    height: 0;
    border-left: .06rem solid transparent;
    border-right: .06rem solid transparent;
    border-top: .07rem solid #0071B7;
}

.linkContent {
    width: 100%;
    margin-top: 8px;
    display: flex;
    flex-wrap: wrap;
    min-height: 50px;
}

.linkContent span {
    font-size: .16rem;
    margin-right: .4rem;
    line-height: .4rem;
    cursor:pointer;
}

</style>