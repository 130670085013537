<template>
  <div class="content">
    <div class="contentHead">
      <h4></h4>
      <p>当前位置：首页>{{ `${title}`}}<span v-if="articlesVo.msgInfo">{{ `(${articlesVo.msgInfo.split('@').join('')})` }}</span></p>
    </div>
    <div class="detailsTitle">
      {{ articlesVo.title }}
    </div>
    <div class="more">
      <span>作者： {{ articlesVo.pubUser }}</span>
      <span>来源： {{ articlesVo.source }}</span>
      <span>日期： {{ articlesVo.pubTime }}</span>
      <span>点击： {{ articlesVo.viewnum }}</span>
    </div>
    <div v-html="articlesVo.content" class="detailsContent">
    </div>
  </div>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      default: "政策法规"
    },

    // 文章实体
    articlesVo: {
      type: Object,
      default() {
        return {

        }
      }
    }
  },
  data() {
    return {
    }
  },

  mounted() {
    console.log('articlesVo', this.articlesVo)
  },

  methods: {
    
  }
}
</script>

<style scoped>
.content {
  width: 10.52rem;
  min-height: 420px;
}

.contentHead {
  width: 100%;
  height: .6rem;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  border-bottom: 4px solid transparent;
  align-items: center;
  margin-bottom: .2rem;
}

.contentHead h4 {
  /* font-size: .22rem; */
  font-size: .19rem !important;
  font-weight: bold;
  color: #000000;
  padding:0 .24rem;
  position: relative;
  height: 100%;
  line-height: .56rem;
}


.contentHead p {
  font-size: .16rem;
  font-weight: 400;
  color: #777777;
}

.detailsTitle {
  font-size: .25rem;
  font-weight: bold;
  color: #000000;
  text-align: center;
  line-height: .38rem;
  margin-bottom: .2rem;
}

.more {
  width: 100%;
  height: .38rem;
  display: flex;
  align-items: center;
  border-bottom: 1px dashed #777;
  margin-bottom: .26rem;
  justify-content: center;
}

.more span {
  margin-right: .5rem;
  font-size: .16rem;
  font-weight: 400;
  color: #777777;
}

.detailsContent{
  /* font-size: .22rem; */
  font-size: .18rem !important;
  font-weight: 400;
  color: #000000;
  line-height: .38rem;
  margin-bottom: .68rem;
}

.detailsContent p {
  text-indent: 32px;
}

.detailsContent img {
  width: 100%;
}

strong {
  font-weight: bold !;
}

</style>
