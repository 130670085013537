<!--
 * @Author: your name
 * @Date: 2021-10-10 15:38:02
 * @LastEditTime: 2022-05-07 00:17:37
 * @LastEditors: xiaoxuesheng 3832702@qq.com
 * @Description: In User Settings Edit
 * @FilePath: /guanwang/src/pages/details/index.vue
-->
<template>
  <div class="homeBox">
    <div class="boxContent">
      <Sidebar />
      <DetailsComp :articlesVo="articlesVo" :title="getTitle"/>
    </div>
  </div>
</template>

<script>
import Sidebar from "../../components/sidebar"
import DetailsComp from "../../components/detailsComp"

import { getArticlesInfo, getOtherArticlesInfo } from "../../api/api.js"

export default {
  components: {
    Sidebar,
    DetailsComp
  },
  
  data() {
    return {
      id: null, // 文章 id
      type: '01',
      articlesVo: {}, // 文章信息
    }
  },

  computed: {
    getTitle() {
      if (this.type === '01') {
        return '滚动通知'
      } else if (this.type === '02') {
        return '新闻动态'
      } else if (this.type === '03') {
        return '通知公告'
      } else if (this.type === '04') {
        return '法律法规'
      } else if (this.type === '05') {
        return '政策文件'
      } else if (this.type === '06') {
        return '政策解读'
      } else if (this.type === '07') {
        return '发布公告'
      } else if (this.type === '08') {
        return '立项查询'
      } else if (this.type === '09') {
        return '标准查询'
      } else if (this.type === '10') {
        return '征求意见'
      } else if (this.type === '111') {
        return '其他平台'
      }
    }
  },

  mounted() {
    this.id = this.$route?.query.id
    this.type = this.$route?.query.type || '02'
    this.getInfo()
  },

  methods: {
    /**
     * @description: 获取详情 
     */    
    async getInfo() {

      try {
        const { id } = this
        if (id == null) {
          return this.articlesVo = {}
        }

        let articlesVo = null

        if (this.type !== '111') {
          articlesVo = await getArticlesInfo(id)
        } else {
          articlesVo = await getOtherArticlesInfo(id)
          articlesVo = { isOther: true, ...articlesVo, content: articlesVo.introduce }
        }

      

        this.articlesVo = articlesVo
      }catch(e) {
        this.articlesVo = { isOther: true }
        console.log(e)
      }
    }
  }
}
</script>

<style scoped>

.boxContent {
  width: 14rem;
  display: flex;
  justify-content: space-between;
  height: 100%;
  min-height: 100vh;
  margin: 0 auto;
  min-width: 960px;
  padding-top: 0.2rem;
}

</style>