/*
 * @Author: your name
 * @Date: 2021-10-19 20:34:19
 * @LastEditTime: 2023-02-21 11:56:05
 * @LastEditors: xiaoxuesheng 3832702@qq.com
 * @Description: In User Settings Edit
 * @FilePath: /guanwang/src/api/api.js
 */
import { fetch, post } from "./index.js"

// /business/index/details
/**
 * @description: 首页列表
 * @param {Object} params 
 */
export const getIndexDetails = async function(id, params = {}) {
    return await fetch(`/ctcs-portal/business/index/details`, params)
}

// /business/contacts/list
/**
 * @description: 联系我们
 * @param {Object} params 
 */
 export const getContactsDetails = async function(id, params = {}) {
    return await fetch(`/ctcs-portal/business/contacts/list`, params)
}


/**
 * @description: 文章列表
 * @param {Object} params 
 */
export const getArticlesList = async function(params = {}) {
    return await fetch("/ctcs-portal/business/articles/list", params)
}

/**
 * @description: 信息查询(立项/标准)
 * @param {Object} params 
 */
 export const getLixiangList = async function(params = {}) {
    return await fetch("/ctcs-portal/business/lixiang/list", params)
}


/**
 * @description: 信息查询(立项/标准)
 * @param {Object} params 
 */
 export const getDraftList = async function(params = {}) {
    return await fetch("/ctcs-portal/business/draft/list", params)
}



/**
 * @description: 详情列表
 * @param {String} id 
 * @param {Object} params 
 */
export const getArticlesInfo = async function(id, params = {}) {
    return await fetch(`/ctcs-portal/business/articles/info/${id}`, params)
}

/**
 * @description: 标准
 * @param {String} id 
 * @param {Object} params 
 */
 export const getlixiangInfo = async function(id, params = {}) {
    return await fetch(`/ctcs-portal/business/lixiang/info/${id}`, params)
}

/**
 * @description: 标准
 * @param {String} id 
 * @param {Object} params 
 */
 export const getDraftInfo = async function(id, params = {}) {
    return await fetch(`/ctcs-portal/business/draft/info/${id}`, params)
}

/**
 * @description: 其他平台详情
 * @param {String} id 
 * @param {Object} params 
 */
 export const getOtherArticlesInfo = async function(id, params = {}) {
    return await fetch(`/ctcs-portal/business/otherplat/detail/${id}`, params)
}

// /business/downcenter/list
/**
 * @description: 下载列表
 * @param {Object} params 
 */
export const getDowncenterList = async function(params = {}) {
    return await fetch("/ctcs-portal/business/downcenter/list", params)
}

/**
 * @description: 热标委组成
 * @param {Object} params 
 */
export const getStandpersonInfo = async function(params = {}) {
    return await fetch("/ctcs-portal/business/standperson/info", params)
}


/**
 * @description: 热标委介绍
 * @param {Object} params 
 */
export const getIntroduce = async function(params = {}) {
    return await fetch("/ctcs-portal/business/articles/introduce", params)
}

// /**
//  * @description: 热标委介绍
//  * @param {Object} params 
//  */
// export const getConstitution = async function(params = {}) {
//     return await fetch("/ctcs-portal/business/constitution/list", params)
// }

/**
 * @description: 热标委章程
 * @param {Object} params 
 */
 export const getConstitution = async function(params = {}) {
    return await fetch("/ctcs-portal/business/articles/constitution", params)
}

export const getDowncenter = async function(url) {
    return await fetch(url, {}, {}, { responseType: 'blob' })
}   

/**
 * @description: 其他平台
 * @param {Object} params 
 */
export const getOtherplatInfo = async function(params = {}) {
    return await fetch("/ctcs-portal/business/otherplat/info", params)
}