<!--
 * @Author: your name
 * @Date: 2021-10-10 15:31:42
 * @LastEditTime: 2021-11-23 20:49:37
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /guanwang/src/pages/search/index.vue
-->
<template>
  <div class="box">
    <div class="boxContent">
      <Sidebar />
      <ListComp
        :page.sync="page"
        :limit.sync="limit"
        :list="list"
        :count="count"
        @getList="getList"
        title="搜索结果"
      />
    </div>
  </div>
</template>

<script>
import Sidebar from "../../components/sidebar"
import ListComp from "../../components/listComp"

import { getArticlesList } from "../../api/api.js"

export default {
  components: {
    Sidebar,
    ListComp,
  },
  data() {
    return {
      page: 1,
      list: [],
      count: 0,
      limit: 20,
      title: "",
    }
  },
  watch: {
    $route(to) {
      this.title = to.query.search
      this.getList()
    }
  },
  mounted() {
    this.title = this.$route.query.search
    this.getList()
  },
  methods: {
    /**
     * @description: 获取文章列表
     */
    async getList() {
      this.list = []
      this.count = 0
      try {
        const { page, limit } = this
        const { list, totalCount } = await getArticlesList({
          page,
          limit,
          title: this.title,
        })
        this.list = list
        this.count = totalCount
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>

<style scoped>
.boxContent {
  width: 14rem;
  display: flex;
  justify-content: space-between;
  height: 100%;
  min-height: 100vh;
  margin: 0 auto;
  min-width: 960px;
  padding-top: 0.2rem;
}
</style>
