<!--
 * @Author: your name
 * @Date: 2021-12-04 01:09:28
 * @LastEditTime: 2022-05-06 10:28:13
 * @LastEditors: xiaoxuesheng 3832702@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /guanwang/src/components/noticeComp.vue
-->
<template>
  <div class="advert-top" v-if="message">
    <div class="marquee-wrap">
      <ul class="marquee-box" id="marquee-box">
        <li class="marquee-list" v-for="i in 2" :key="i" :id="i==1?'marquee':''">
            <span @click="clickHandler(item)" v-for="(item, index) in message" :key="index">{{ item.title }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script type="text/javascript">
export default {
  props: {
    message: {
      type: Array,
      default() {
          return []
      }
    }
  },
  data() {
    return {

    };
  },
  mounted: function() {
    // 延时滚动
    setTimeout(() => {
      this.runMarquee();
    }, 1500);
  },
  methods: {
    runMarquee() {
      // 获取文字 计算后宽度
      var width = document.getElementById("marquee").getBoundingClientRect()
          .width,
        marquee = document.getElementById("marquee-box"),
        disx = 0; // 位移距离
      //设置位移
      setInterval(() => {
        disx--; // disx-=1; 滚动步长
        if (-disx >= width) {
          disx = 10; // 如果位移超过文字宽度，则回到起点  marquee-list的margin值
        }
        // marquee.style.transform = 'translateX(' + disx + 'px)'
        marquee.style.left = disx + "px";
      }, 40); //滚动速度
    },

    clickHandler(item) {
        window.open(window.location.origin + '/details' + '?id=' + item.id + '&type=01', '_blank')
    }
  }
};
</script>
<style>

.advert-top {
  position: relative;
  display: flex;
  width: 100%;
  height: 30px;
  min-height: .3rem;
  background-color: #fff;
  color: #2d8cf0;
  font-size: 14px;
  align-items: center;
}
.ico-horn {
  display: flex;
  margin-right: 10px;
  width: 14px;
  height: 14px;
  justify-content: center;
  align-items: center;
}
.ico-horn > img {
  width: 30px;
  height: 30px;
}
/* 以下代码与滚动相关 */
.marquee-wrap {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.marquee-box {
  position: absolute;
  top: 50%;
  display: flex;
  white-space: nowrap;
  transform: translateY(-50%);
}
.marquee-list {
  margin-right: 10px;
} 
.marquee-list span {
  padding: 0 .2rem;
  color: #333;
  cursor:pointer;
  font-size: .18rem;
}
</style>