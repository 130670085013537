<!--
 * @Author: your name
 * @Date: 2021-10-20 20:56:04
 * @LastEditTime: 2023-10-22 17:34:47
 * @LastEditors: xiaoxuesheng 3832702@qq.com
 * @Description: In User Settings Edit
 * @FilePath: /guanwang/src/pages/home/components/linkComp.vue
-->
<template>
<div class="linkComp">
    <ul class="linkHeader">
        <li class="linkHeaderTitle">
            流程简介
        </li>
        <li @click="clickHandler(1)" :class="{ active: activeIndex == 1 }">项目管理</li>
        <li @click="clickHandler(2)" :class="{ active: activeIndex == 2 }">标准制修订</li>
        <li @click="clickHandler(3)" :class="{ active: activeIndex == 3 }">宣贯实施</li>
        <li @click="clickHandler(4)" :class="{ active: activeIndex == 4 }">标准复审</li>
        <li @click="clickHandler(5)" :class="{ active: activeIndex == 5 }">专家申请</li>
    </ul>
    <div v-if="activeIndex == 1" class="linkContent">
        <span>项目信息填报</span>
        <div>
            <img src="../../../common/images/next.png" alt="">
        </div>
        <span>承担单位审核</span>
        <div>
            <img src="../../../common/images/next.png" alt="">
        </div>
        <span>标委会形式审查</span>
        <div>
            <img class="end" src="../../../common/images/next.png" alt="">
        </div>
        <span>组织项目评审</span>
        <div>
            <img src="../../../common/images/next.png" alt="">
        </div>
        <span>推荐报送</span>
        <div>
            <img src="../../../common/images/next.png" alt="">
        </div>
        <span>主管部门批复立项</span>
        <div>
            <img class="end" src="../../../common/images/next.png" alt="">
        </div>
        <span>签订实施方案</span>
        <div>
            <img src="../../../common/images/next.png" alt="">
        </div>
        <span>计划调整</span>
        <div>
            <img src="../../../common/images/next.png" alt="">
        </div>
        <span>项目总结</span>
        <div>
            <img class="end endImg" src="../../../common/images/end.png" alt="">
        </div>
    </div>
    <div v-if="activeIndex == 2" class="linkContent">
        <span>组建起草组</span>
        <div>
            <img src="../../../common/images/next.png" alt="">
        </div>
        <span>起草标准</span>
        <div>
            <img src="../../../common/images/next.png" alt="">
        </div>
        <span>征求意见</span>
        <div>
            <img class="end" src="../../../common/images/next.png" alt="">
        </div>
        <span>标准送审</span>
        <div>
            <img src="../../../common/images/next.png" alt="">
        </div>
        <span>标准审定</span>
        <div>
            <img src="../../../common/images/next.png" alt="">
        </div>
        <span>标准报批</span>
        <div>
            <img class="end" src="../../../common/images/next.png" alt="">
        </div>
        <span>主管部门审查</span>
        <div>
            <img src="../../../common/images/next.png" alt="">
        </div>
        <span>发布公告</span>
        <div>
            <img src="../../../common/images/next.png" alt="">
        </div>
        <span>出版正式文本</span>
        <div>
            <img class="end endImg" src="../../../common/images/end.png" alt="">
        </div>
    </div>
    <div v-if="activeIndex == 3" class="linkContent">
        <span>起草人制作宣传册</span>
        <div>
            <img src="../../../common/images/next.png" alt="">
        </div>
        <span>开展宣传培训</span>
        <div>
            <img src="../../../common/images/next.png" alt="">
        </div>
        <span>提交宣贯实施情况</span>
        <div>
            <img class="end" src="../../../common/images/next.png" alt="">
        </div>
        <span>起草单位审核</span>
        <div>
            <img src="../../../common/images/next.png" alt="">
        </div>
        <span>报送热标委备案</span>
        <div>
            <img class="end endImg" src="../../../common/images/end.png" alt="">
        </div>
    </div>
    <div v-if="activeIndex == 4" class="linkContent">
        <span>起草人提出复审意见</span>
        <div>
            <img src="../../../common/images/next.png" alt="">
        </div>
        <span>起草单位审核</span>
        <div>
            <img src="../../../common/images/next.png" alt="">
        </div>
        <span>起草人提出复审意见</span>
        <div>
            <img class="end" src="../../../common/images/next.png" alt="">
        </div>
        <span>热标委审核确定复审意见</span>
        <div>
            <img src="../../../common/images/next.png" alt="">
        </div>
        <span>报送至主管部门</span>
        <div>
            <img class="end endImg" src="../../../common/images/end.png" alt="">
        </div>
    </div>
    <div v-if="activeIndex == 5" class="linkContent">
        <span>个人信息填报</span>
        <div>
            <img src="../../../common/images/next.png" alt="">
        </div>
        <span>申请成为专家</span>
        <div>
            <img src="../../../common/images/next.png" alt="">
        </div>
        <span>所在单位审核</span>
        <div>
            <img class="end" src="../../../common/images/next.png" alt="">
        </div>
        <span>下载专家申请表</span>
        <div>
            <img src="../../../common/images/next.png" alt="">
        </div>
        <span>上传申请表扫描件</span>
        <div>
            <img src="../../../common/images/next.png" alt="">
        </div>
        <span>热标委审核</span>
        <div>
            <img class="end" src="../../../common/images/next.png" alt="">
        </div>
        <span>聘请专家库成员</span>
        <div>
            <img src="../../../common/images/next.png" alt="">
        </div>
        <span>颁发证书</span>
        <div>
            <img class="end endImg" src="../../../common/images/end.png" alt="">
        </div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            activeIndex: 1, // 当前点击索引
        }
    },

    methods: {
        /**
         * @description: 点击时间
         * @param {Number} index 当前点击索引
         * @return {*}
         */        
        clickHandler(index) {
            this.activeIndex = index
        }
    }
}
</script>

<style scoped>

.linkComp {
     
}

.linkHeader {
    width: 100%;
    height: .4rem;
    box-sizing: border-box;
    background:#EAF5FF;
    font-size: .16rem;
    display: flex;
    font-weight: 400;
}

.linkHeader li {
    padding: 0 .1rem;
    line-height: .45rem;
    color: #0071B7;
    cursor:pointer;
}

.linkHeader .linkHeaderTitle {
    background: #0071B7;
    color: #fff;
    font-size: .18rem;
    font-weight: bold;
    line-height: .4rem;
    display: flex;
    margin-right: .1rem;
}

.linkHeader .linkHeaderTitle img {
    width: .16rem;
    height: .16rem;
    margin-right: .1rem;
}

.active {
    font-weight: bold;
    position: relative;
}

.active:after {
    content: " ";
    position: absolute;
    left: 50%;
    margin-left: -0.05rem;
    bottom: -0.07rem;
    width: 0;
    height: 0;
    border-left: .06rem solid transparent;
    border-right: .06rem solid transparent;
    border-top: .07rem solid #0071B7;
}

.linkContent {
    width: 100%;
    margin-top: 8px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.linkContent span {
    font-size: .16rem;
    background: #0071B7;
    width: 1.5rem;
    height: .4rem;
    color: #fff;
    text-align: center;
    border-radius: 2px;
    line-height: .4rem;
    margin-top: .4rem;
}


.linkContent div {
    width: calc((100% - 4.5rem) / 3);
    border-top: 1px dashed #ccc;
    margin-top: 0.05rem;
    margin-top: .4rem;
    position: relative;
}

.linkContent div img {
    width: .25rem;
    height: .18rem;
    position: absolute;
    left: 50%;
    margin-left: -.1rem;
    top: -0.1rem;
}

.linkContent div .end {
    left: 100%;
    margin-left: -.25rem
}

.linkContent div .endImg {
    width: .18rem;
    height: .18rem;
    margin-left: -.18rem
}
</style>