<!--
 * @Author: your name
 * @Date: 2021-10-10 12:09:01
 * @LastEditTime: 2022-09-15 20:38:51
 * @LastEditors: xiaoxuesheng 3832702@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /guanwang/src/pages/bulletin/index.vue
-->
<template>
  <div class="box">
    <div class="boxContent">
      <Sidebar />
      <ListComp
        :page.sync="page"
        :limit.sync="limit"
        :list="list"
        :count="count"
        @getList="getList"
        title="发布公告(团体标准)"
      />
    </div>
  </div>
</template>

<script>
import Sidebar from "../../components/sidebar"
import ListComp from "../../components/listComp"

import { getArticlesList } from "../../api/api.js"

export default {
  components: {
    Sidebar,
    ListComp,
  },
  data() {
    return {
      page: 1,
      list: [],
      count: 0,
      limit: 20,
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    /**
     * @description: 获取文章列表
     */
    async getList() {
      try {
        const { page, limit } = this
        const { list, totalCount } = await getArticlesList({
          type: "07",
          page,
          limit,
          bzlb: "05"
        })
        this.list = list
        this.count = totalCount
      } catch (e) {
        console.log(e)
        this.list = []
        this.count = 0
      }
    },
  },
}
</script>

<style scoped>
.boxContent {
  width: 14rem;
  display: flex;
  justify-content: space-between;
  height: 100%;
  min-height: 100vh;
  margin: 0 auto;
  min-width: 960px;
  padding-top: 0.2rem;
}
</style>
