import Home from '@/pages/home/index.vue'

import Bulletin from '@/pages/bulletin/index.vue'
import NationalStandard from "../pages/nationalStandard/index.vue"
import IndustryStandard from "../pages/IndustryStandard/index.vue"
import LocalStandards from "../pages/localStandards/index.vue"
import GroupStandards from "../pages/groupStandards/index.vue"
import News from '@/pages/news/index.vue'
import Notice from '@/pages/notice/index.vue'
import Process from '@/pages/process/index.vue'
import Rules from '@/pages/rules/index.vue'
import Training from '@/pages/training/index.vue'
import WorkDynamic from '@/pages/workDynamic/index.vue'
import Search from '@/pages/search/index.vue'
import Details from '@/pages/details/index.vue'
import QueryDetails from '@/pages/queryDetails/index.vue'
import Map from '@/pages/map/index.vue'
import Organization from "@/pages/organization/index.vue"
import Download from "@/pages/download/index.vue"
import ContactUs from "@/pages/contactUs/index.vue"
import Opinion from "@/pages/opinion/index.vue"
import PolicyFile from "@/pages/policyFile/index.vue"
import Standard from "@/pages/standard/index.vue"
// Unscramble
import Unscramble from "@/pages/unscramble/index.vue"

const routers = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      tag: "0"
    }
  },
  // 国际标准
  {
    path: '/bulletin',
    name: 'bulletin',
    component: Bulletin,
    meta: {
      tag: "11-2"
    }
  },
  // 国家标准
  {
    path: '/nationalStandard',
    name: 'nationalStandard',
    component: NationalStandard,
    meta: {
      tag: "11-1"
    }
  },
  // 行业标准
  {
    path: '/industryStandard',
    name: 'industryStandard',
    component: IndustryStandard,
    meta: {
      tag: "11-3"
    }
  },
  // 地方标准
  {
    path: '/localStandards',
    name: 'localStandards',
    component: LocalStandards,
    meta: {
      tag: "11-4"
    }
  },
  // 团体标准
  {
    path: '/groupStandards',
    name: 'groupStandards',
    component: GroupStandards,
    meta: {
      tag: "11-5"
    }
  },
  // 征求意见
  {
    path: '/opinion',
    name: 'opinion',
    component: Opinion,
    meta: {
      tag: "8"
    }
  },
  // Standard
  {
    path: '/standard',
    name: 'standard',
    component: Standard,
    meta: {
      tag: "13"
    }
  },
  // 新闻资讯
  {
    path: '/news',
    name: 'news',
    component: News,
    meta: {
      tag: "10"
    }
  },
  // 通知通告
  {
    path: '/notice',
    name: 'notice',
    component: Notice,
    meta: {
      tag: "7"
    }
  },
  // 业务流程
  {
    path: '/process',
    name: 'process',
    component: Process,
    meta: {
      tag: "2-3"
    }
  },
  // 政策法规
  {
    path: '/rules',
    name: 'rules',
    component: Rules,
    meta: {
      tag: "2-1"
    }
  },
  // 政策文件
  {
    path: '/policyFile',
    name: 'policyFile',
    component: PolicyFile,
    meta: {
      tag: "2-2"
    }
  },
  // 政策解读
  {
    path: '/unscramble',
    name: 'unscramble',
    component: Unscramble,
    meta: {
      tag: "2-3"
    }
  },
  // 学习培训
  {
    path: '/training',
    name: 'training',
    component: Training,
    meta: {
      tag: "3-2"
    }
  },
  // 工作动态
  {
    path: '/workDynamic',
    name: 'workDynamic',
    component: WorkDynamic,
    meta: {
      tag: "3-1"
    }
  },
  // 搜索页面
  {
    path: '/search',
    name: 'search',
    component: Search,
    meta: {
      tag: "0"
    }
  },
  // 详情
  {
    path: '/details',
    name: 'details',
    component: Details,
    meta: {
      tag: "-1"
    }
  },
  // QueryDetails
  {
    path: '/querydetails',
    name: 'querydetails',
    component: QueryDetails,
    meta: {
      tag: "-1"
    }
  },
  // 网站地图
  {
    path: '/map',
    name: 'map',
    component: Map,
    meta: {
      tag: "-1"
    }
  },
  // 组织结构
  {
    path: '/organization',
    name: 'organization',
    component: Organization,
    meta: {
      tag: "1"
    }
  },
  // 下载
  {
    path: '/download',
    name: 'download',
    component: Download,
    meta: {
      tag: "4"
    }
  },
  // 联系我们
  {
    path: '/contactUs',
    name: 'contactUs',
    component: ContactUs,
    meta: {
      tag: "6"
    }
  },
  {
    path: "*",
    redirect: "/"
  }
]

// Download
export default routers