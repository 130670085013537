<template>
  <div class="column">
    <div class="columnHead">
      <h4 @click="switchHandler(0)" :class="{ active: activeIndex === 0 }">法律法规</h4>
      <h4 @click="switchHandler(1)" :class="{ active: activeIndex === 1 }">政策文件</h4>
      <h4 @click="switchHandler(2)" style="margin-right: auto" :class="{ active: activeIndex === 2 }">政策解读</h4>
      <p @click="detailsHandler">更多 >></p>
    </div>
    <ul>
      <li
        v-for="(item, index) in list"
        :key="index"
        :class="{ bold: item.topFlag === 1 }"
        @click="jumpDetails(item)"
      >
        <p>{{ item.title }}</p>
        <span>{{ item.pubTime }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    policyLaw: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      activeIndex: 0
    }
  },
  computed: {
    list() {
      if (this.policyLaw[this.activeIndex]) {
        return this.policyLaw[this.activeIndex].slice(0, 7)
      }
      return []
    }
  },
  methods: {
    switchHandler(index) {
      this.activeIndex = index
    },
    // 详情
    detailsHandler() {

      if (this.activeIndex === 0) {
        this.$router.push({ name: "rules" })
      }
      if (this.activeIndex === 1) {
        this.$router.push({ name: "policyFile" })
      }
       if (this.activeIndex === 2) {
        this.$router.push({ name: "unscramble" })
      }
    },

    /**
     * @description: 跳转详情
     * @param {Object} item
     * @return {*}
     */

    jumpDetails(item) {
      

      if (this.activeIndex === 0) {
        window.open(
          window.location.origin + "/details" + "?id=" + item.id + "&type=04",
          "_blank"
        )
      }

      if (this.activeIndex === 1) {
        window.open(
          window.location.origin + "/details" + "?id=" + item.id + "&type=05",
          "_blank"
        )
      }

      if (this.activeIndex === 2) {
        window.open(
          window.location.origin + "/details" + "?id=" + item.id + "&type=06",
          "_blank"
        )
      }
      //this.$router.push({ name: "details", query: { id: item.id } })
    },
  },
}
</script>

<style scoped>
.bold p, bold span{
  font-weight: bold;
}
.column {
  height: 4.37rem;
  margin-bottom: 0.18rem;
  border: 1px solid #d2efff;
  box-sizing: border-box;
  overflow: hidden;
}

.columnHead {
  width: 100%;
  height: 0.38rem;
  border-top: 0.05rem solid #d2efff;
  margin-bottom: 0.25rem;
  background: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 5px;
}

.columnHead p {
  color: #999999;
  font-size: 0.12rem;
  padding-right: 0.2rem;
  cursor: pointer;
  height: 0.38rem;
  line-height: 0.38rem;
}

.columnHead h4 {
  padding: 0 0.13rem;
  color: #0071B7;
  font-size: 0.18rem;
  font-weight: bold;
  height: .38rem;
  display: flex;
  align-items: center;
  cursor:pointer;
}

.columnHead .active {
  background: #0071b7;
  color: #fff;
  font-weight: bold;
}

.column ul {
  width: 100%;
  box-sizing: border-box;
  padding: 0 5px;
}

.column li {
  width: 100%;
  height: 0.52rem;
  line-height: 0.52rem;
  font-size: 0.18rem;
  font-weight: 400;
  color: #000000;
  position: relative;
  display: flex;
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 0.9rem;
  cursor: pointer;
}

.column li p {
  width: 90%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.18rem;
}

.column li span {
  position: absolute;
  width: 1.2rem;
  height: 0.42rem;
  right: 0;
  top: 0;
  line-height: 0.42rem;
  text-align: right;
  color: #9fa0a0;
}

.column li::after {
  content: " ";
  width: 4px;
  height: 4px;
  background: #b7b7b7;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -2px;
  border-radius: 2px;
}
</style>
