<template>
  <div class="content">
    <div class="contentHead">
      <h4>下载中心</h4>
      <p>当前位置：首页>下载中心</p>
    </div>
    <div class="listContent">
      <ul class="constitution">
        <li v-for="(item, index) in list" :key="index">
          <h5 @click="downHandler(item)">{{ item.fileName }}</h5>
          <p>发布时间：{{ item.pubTime }}</p>
          <span @click="downHandler(item)">下载 </span>
        </li>
      </ul>
    </div>
    <el-pagination
      background
      :page-size="limit"
      @size-change="sizeChangeHandler"
      @current-change="currentChangeHaddler"
      :current-page="page"
      :total="count"
    >
    </el-pagination>
  </div>
</template>

<script>
import { getDowncenter } from "../api/api"

export default {
  props: {
    // 标题
    title: {
      type: String,
      default: "政策法规",
    },

    // 列表
    list: {
      type: Array,
      default() {
        return []
      },
    },

    // 数量
    count: {
      type: Number,
      default: 0,
    },

    // 每页数量
    limit: {
      type: Number,
      default: 10, // 每页显示条数
    },

    // 当前页
    page: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {}
  },

  methods: {
    /**
     * @description:  下载
     */
    async downHandler(item) {
      if (!item.url) {
        return;
      }
      const res = await getDowncenter(item.url)
      let url = URL.createObjectURL(new Blob([res.data]));
       let a = document.createElement("a");
       document.body.appendChild(a);
       a.href = url;
       let arr = item.url.split('.')
       a.download = `${item.fileName}.${arr[arr.length - 1]}`;
       a.target = "_blank";
       a.click();
       a.remove();
    },

    /**
     * @description: pageSize 改变时会触发
     * @param {Number} num 每页数量
     * @return {*}
     */
    sizeChangehandler(num) {
      this.$emit("update:limit", num)
    },

    /**
     * @description: pageSize 改变时会触发
     * @param {Number} num 每页数量
     * @return {*}
     */
    sizeChangeHandler(num) {
      this.$emit("update:limit", num)
      this.$emit("getList")
    },

    /**
     * @description: currentPage 改变时会触发
     * @param {Number} num 当前页
     * @return {*}
     */
    currentChangeHaddler(num) {
      this.$emit("update:page", num)
      this.$emit("getList")
    },
  },
}
</script>

<style scoped>
.content {
  width: calc(100% - 3.5rem);
}

.contentHead {
  width: 100%;
  height: 0.6rem;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  border-bottom: 4px solid #eef9ff;
  align-items: center;
}

.contentHead h4 {
  /* font-size: 0.22rem; */
  font-size: .19rem !important;
  font-weight: bold;
  color: #000000;
  padding: 0 0.24rem;
  position: relative;
  height: 100%;
  line-height: 0.56rem;
}

.contentHead h4:after {
  content: " ";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -4px;
  height: 4px;
  background: #1157af;
}

.contentHead p {
  font-size: 0.16rem;
  font-weight: 400;
  color: #777777;
}

.listContent {
  width: 100%;
  height: calc(100% - 1.4rem);
  margin-bottom: 0.2rem;
  overflow-y: auto;
}

.constitution {
  width: 100%;
}

.constitution li {
  width: 100%;
  height: 0.72rem;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: flex-start;
  position: relative;
  border-bottom: 1px solid #cccccc;
  cursor:pointer;
}

.constitution h5 {
  font-weight: 400;
  /* font-size: 0.22rem; */
  font-size: .18rem !important;
  color: #000;
  width: 50%;
}

.constitution p {
  font-weight: 400;
  font-size: 0.18rem;
  color: #888;
}

.constitution span {
  position: absolute;
  right: 0;
  top: 0.2rem;
  background: #1157af;
  width: 0.98rem;
  height: 0.32rem;
  text-align: center;
  line-height: 0.32rem;
  color: #fff;
  font-size: 0.16rem;
  border-radius: 2px;
}
</style>

<style>
.content .active {
  background: #0071b7 !important;
}
</style>
