<template>
  <div class="dynamic">
    <div class="dynamicHead">
      <h5 :class="{ active: active == 0 }">新闻动态</h5>
      <p @click="detailsHandler">更多 >> </p>
    </div>
    <ul>
      <li v-for="(item, index) in newsDync.slice(0, 10)" :key="index" :class="{ bold: item.topFlag === 1}" @click="jumpDetails(item)">
        <p>{{ item.title }}</p>
        <span>{{ item.pubTime }}</span>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  props: {
    newsDync: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      active: 0
    }
  },

  methods :{
    // 详情
    detailsHandler() {
        this.$router.push({ name: "news" })
    },

    /**
     * @description: 跳转详情 
     * @param {Object} item 文章详情
     * @return {*}
     */    
    jumpDetails(item) {    
      window.open(window.location.origin + '/details' + '?id=' + item.id + '&type=02' , '_blank')
      // this.$router.push({ name: "details", query: { id: item.id } })
    }
  }
}
</script>

<style scoped>
.bold p, bold span{
  font-weight: bold;
}
.dynamic {
  height: 4.76rem;
  box-sizing: border-box;
  border-top: none;
}

.dynamicHead {
  width: 100%;
  height: .38rem;
  margin-bottom: .25rem;
  background: #F8F8F8;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dynamicHead p {
  color: #999999;
  font-size: .12rem;
  padding-right: .2rem;
  margin-left: auto;
  cursor:pointer;
  height: 100%;
  line-height: .38rem;
}

.dynamicHead h5 {
  padding: 0 0.13rem;
  background: #0071b7;
  color: #fff;
  font-size: 0.18rem;
  font-weight: bold;
  height: .36rem;
  display: flex;
  align-items: center;
  margin-right: 0.1rem;
}

.dynamicHead .active {
  background: #0071B7;
  color: #FFFFFF;
}

.dynamicFirst {
  width: 100%;
  display: flex;
  margin-bottom: .15rem;
}

.dynamicFirst img {
  width: 2.67rem;
  height: 1.73rem;
}

.dynamicInfo {
  width: 2.92rem;
  margin-left: .2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dynamicInfoTitle {
  font-size: .18rem;
  font-weight: 400;
  color: #000000;
  width: 100%;
  margin-bottom: .12rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.dynamicInfo p {
  font-size: .14rem;
  width: 100%;
  color: #595959;
  position: relative;
  line-height: .24rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}

.dynamicInfo span {
  font-size: .14rem;
  font-weight: 400;
  color: #898989;
  line-height: .24rem;
  height: .24rem;
  padding-left: 10px;
}


.dynamic ul {
  width: 100%;
  box-sizing: border-box;
  padding: 0 .06rem;
}

.dynamic li {
  width: 100%;
  height: .42rem;
  line-height: .42rem;
  font-size: .18rem;
  font-weight: 400;
  color: #000000;
  position: relative;
  display: flex;
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: .9rem;
}

.dynamic li p {
  width: 90%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor:pointer;
  font-size: .18rem;
}

.dynamic li span {
  position: absolute;
  width: 1.2rem;
  height: .42rem;
  right: 0;
  top: 0;
  line-height: .42rem;
  text-align: right;
  color: #9FA0A0;
}

.dynamic li::after {
  content: " ";
  width: 4px;
  height: 4px;
  background: #B7B7B7;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -2px;
  border-radius: 2px;
}

</style>