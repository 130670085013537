<template>
  <div class="content">
    <div class="contentHead">
      <h4>{{ title }}</h4>
      <p>当前位置：首页>{{ title }}</p>
    </div>
    <div class="listContent">
      <div v-if="isSearch" class="listSearch">
        <el-input placeholder="请输入搜索条件" v-model="name"></el-input>
        <el-button @click="searchHandler" type="primary">查询</el-button>
      </div>
      <ul>
        <li v-for="(item, index) in list" :key="index" @click="jumpDetails(item.id, item)">
          <p>{{ item.title }}</p>
          <span>{{ item.pubTime }}</span>
        </li>
      </ul>
    </div>
    <el-pagination
        background
        :page-size="limit"
        @size-change="sizeChangeHandler"
        @current-change="currentChangeHaddler"
        :current-page="page"
        :total="count">
    </el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    // 标题
    title: {
      type: String,
      default: "政策法规"
    },

    // 列表
    list: {
      type: Array,
      default() {
        return []
      }
    },

    // 数量
    count: {
      type: Number,
      default: 0
    },

    isSearch: {
      type: Boolean,
      default: false
    },

    // 每页数量
    limit: {
      type: Number,
      default: 10, // 每页显示条数
    },

    // 当前页
    page: {
      type: Number,
      default: 1
    },

    type: {
      type: String,
      default: '01'
    }
  },
  data() {
    return {
      name: ""
    }
  },

  methods: {
    /**
     * @description:  跳转详情
     */    
    jumpDetails(id, item) {
      if (item.isNotClick) {
        return
      }
      window.open(window.location.origin + '/details' + '?id=' + id + '&type=' + this.type, '_blank')
    },

    /**
     * @description: pageSize 改变时会触发
     * @param {Number} num 每页数量
     * @return {*}
     */    
    sizeChangehandler(num) {
      this.$emit("update:limit", num)
    },

    searchHandler() {
      this.$emit("update:page", 1)
      this.$emit("getList", this.name)
    },

    /**
     * @description: pageSize 改变时会触发
     * @param {Number} num 每页数量
     * @return {*}
     */    
    sizeChangeHandler(num) {
      this.$emit("update:limit", num)
      this.$emit("getList", this.name)
    },

    /**
     * @description: currentPage 改变时会触发 
     * @param {Number} num 当前页
     * @return {*}
     */    
    currentChangeHaddler(num) {
      this.$emit("update:page", num)
      this.$emit("getList", this.name)
    }
  }
}
</script>

<style scoped>
.content {
  width: calc(100% - 3.5rem);
  height: 100%;
}

.listSearch {
  width: 100%;
  display: flex;
  padding: .2rem 0;
  margin-top: .3rem;
}

.listSearch div {
    width: 30% !important;
    margin-right: 15px;
}

.contentHead {
  width: 100%;
  height: .6rem;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  border-bottom: 4px solid #EEF9FF;
  align-items: center;
}

.contentHead h4 {
  /* font-size: .22rem; */
  font-size: .19rem !important;
  font-weight: bold;
  color: #000000;
  padding:0 .24rem;
  position: relative;
  height: 100%;
  line-height: .56rem;
}

.contentHead h4:after {
  content: " ";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -4px;
  height: 4px;
  background: #1157AF;
}

.contentHead p {
  font-size: .16rem;
  font-weight: 400;
  color: #777777;
}

.listContent {
  width: 100%;
  height: calc(100% - 1.4rem);
  margin-bottom: .2rem;
  overflow-y: auto;
  min-height: calc( 100vh - 1.4rem);
}

.listContent ul {
  width: 100%;
  padding-top: .24rem;
}

.listContent li {
  /* font-size: .22rem; */
  font-size: .18rem !important;
  font-weight: 400;
  color: #000000;
  display: flex;
  justify-content: space-between;
  height: .6rem;
  line-height: .6rem;
  box-sizing: border-box;
  border-bottom: 1px dashed #777777;
  cursor:pointer;
}

.listContent span {
  width: 1.5rem;
  text-align: right;
}

.listContent p {
  width: calc(100% - 1.5rem);
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
  position: relative;
  text-indent: .16rem;
  cursor:pointer;
  font-size: .18rem !important;
}

.listContent p:after{
  content: " ";
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background: #000;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -2px;
}
</style>

<style>
.content .active {
  background: #0071B7 !important;
}
</style>