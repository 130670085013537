<!--
 * @Author: your name
 * @Date: 2021-10-09 22:58:39
 * @LastEditTime: 2021-11-23 20:29:53
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /guanwang/src/pages/home/components/adv.vue
-->
<template>
  <ul class="adv">
    <li @click="jumpHandler('workDynamic')">
      <p>立 项 查 询</p>
      <img src="../../../common/images/inquire01.png" alt="">
    </li>
    <li @click="jumpHandler('training')">
      <p>标 准 查 询</p>
      <img src="../../../common/images/inquire02.png" alt="">
    </li>
    <li @click="jumpHandler('download')">
      <p class="color007B2E">下 载 中 心</p>
      <img src="../../../common/images/download.png" alt="">
    </li>
    <li @click="jumpHandler('contactUs')">
      <p class="colorFD6503E">联 系 我 们</p>
      <img src="../../../common/images/opinoin.png" alt="">
    </li>
  </ul>
</template>

<script>

export default {
  components: {

  },
  data() {
    return {
    }
  },
  methods: {
    jumpHandler(path) {
      if (this.$route.name !== path) this.$router.push({ name: path })
    }
  }
}
</script>

<style scoped>
.adv {
  width: 14rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: .24rem;
}

.adv li {
  width: calc(25% - 5px);
  display: flex;
  align-items: center;
  justify-content: center;
  height: .9rem;
  margin-bottom: .14rem;
  position: relative;
  cursor:pointer;
}

.adv li p {
  position: absolute;
  height: .36rem;
  top: 50%;
  margin-top: -0.18rem;
  left: 0;
  width: 100%;
  font-weight: bold;
  color: #0E2A5D;
  font-size: .36rem;
  text-align: center;
  display: flex;
  align-items: center;
  font-family: Microsoft YaHei;
  justify-content: center;
}

.adv li span {
  padding:0 .2rem;
  height: .26rem;
  line-height: .26rem;
  background: #0071B7;
  border-radius: 13px;
  font-size: .14rem;
  color: #fff;
  position: absolute;
  left: 74%;
}

.adv img {
  width: 100%;
  height: 100%;
}
</style>