<template>
  <div class="dynamic">
    <div class="dynamicHead">
      <h5 @click="switchHandler(0)" :class="{ active: activeIndex === 0 }">通知公告</h5>
      <h5 @click="switchHandler(1)" style="margin-right: auto" :class="{ active: activeIndex === 1 }">征求意见</h5>
      <p @click="detailsHandler">更多 >> </p>
    </div>
    <ul>
      <li v-for="(item, index) in list" :key="index" :class="{ bold: item.topFlag === 1 }" @click="jumpDetails(item)">
        <div>
          <span>{{ item.pubTime.split('-')[0] }}</span>
          <span>{{ item.pubTime.split('-')[1] }}-{{ item.pubTime.split('-')[2] }}</span>
        </div>
        <p>{{ item.title }}</p>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  props: {
    noticeMsg: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      activeIndex: 0
    }
  },
  computed: {
    list() {
      if (this.noticeMsg[this.activeIndex]) {
        return this.noticeMsg[this.activeIndex].slice(0, 5)
      }
      return []
    }
  },
  methods :{
    switchHandler(index) {
      this.activeIndex = index
    },
    // 详情
    detailsHandler() {
      if (this.activeIndex === 0) {
        this.$router.push({ name: "notice" })
      }
      if (this.activeIndex === 1) {
        this.$router.push({ name: "opinion" })
      }
    },

    /**
     * @description: 跳转详情
     */    
    jumpDetails(item) {
      if (this.activeIndex === 0) {
        window.open(window.location.origin+'/details' + '?id=' + item.id + '&type=03', '_blank')
      }
      if (this.activeIndex === 1) {
        window.open(window.location.origin+'/details' + '?id=' + item.id + '&type=10', '_blank')
      }
    }
  }
}
</script>

<style scoped>
.bold p, bold span{
  font-weight: bold;
}
.dynamic {
  height: 4.37rem;
  box-sizing: border-box;
  border: 1px solid #D2EFFF;
  border-top: none;
  overflow: hidden;
}

.dynamicHead {
  width: 100%;
  height: .38rem;
  border-top: .05rem solid #D2EFFF;
  margin-bottom: .25rem;
  background: #F8F8F8;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dynamicHead p {
  color: #999999;
  font-size: .12rem;
  padding-right: .2rem;
  cursor:pointer;
  height: .38rem;
  line-height: .38rem;
}

.dynamicHead h5 {
  padding: 0 0.13rem;
  color: #0071B7;
  font-size: 0.18rem;
  font-weight: bold;
  height: .38rem;
  display: flex;
  align-items: center;
  cursor:pointer;
}

.dynamicHead .active {
  background: #0071b7;
  color: #fff;
  font-weight: bold;
}

.dynamic ul {
  width: 100%;
  box-sizing: border-box;
  padding:0 .08rem;
}

.dynamic li {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: .18rem;
  font-weight: 400;
  color: #000000;
  line-height: .28rem;
  margin-bottom: .2rem;
  cursor:pointer;
}

.dynamic li div {
  width: 1rem;
  height: .46rem;
  background: #0071B7;
  display: flex;
  flex-direction: column;
  font-size: .13rem;
  line-height: 0.18rem;
  color: #fff;
  align-items: center;
  justify-content: center;
}

.dynamic li div span {
  margin-bottom: .02rem;
  color: #FEFEFE;
}

.dynamic li div span:first-child {
  font-size: .15rem;
  color: #FEFEFE;
}

.dynamic li p {
  padding-left: .15rem;
  width: calc(100% - 1.1rem);
  font-size: .18rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
</style>