<template>
  <div class="news">
    <swiper ref="mySwiper" class="shuffling" :options="swiperOptions">
      <swiper-slide v-for="(item, index) in scrollImgs" :key="index" @click.native="jumpDetails(item)">
        <img class="swiperImg" :src="item.url" alt="">
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>

    <div class="newTitle">{{ scrollImgs[activeIndex] && scrollImgs[activeIndex].artMsg }}</div>
  </div>
</template>

<script>

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  props: {
    scrollImgs: {
      type: Array,
      default() {
        return []
      }
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        },
        on:{
        slideChange: ()=>{
          this.activeIndex = this.$refs.mySwiper.$swiper.activeIndex
        }
        }},
        activeIndex: 0,
      imgs: [], // 图片列表
    }
  },
  methods :{
    // 详情
    detailsHandler() {
      this.$router.push({ name: "news" })
    },

    jumpDetails(item) {
      window.open(window.location.origin + '/details' + '?id=' + item.artId + '&type=' + item.artType, '_blank')
      //this.$router.push({ name: "details" })
    }
  }
}
</script>


<style scoped>

.news {
  position: relative;
}

.newHead {
  position: absolute;
  width: 100%;
  height: .42rem;
  background: rgba(0,0,0,.6);
  z-index: 11;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.newHead span {
  color: #999999;
  font-size: .12rem;
  padding-right: .2rem;
  cursor:pointer;
  height: .42rem;
  line-height: .42rem;
}

.newTitle {
  position: absolute;
  bottom: 0;
  left: 0;
  max-width: 75%;
  height: .42rem;
  line-height: .48rem;
  z-index: 11;
  font-size: .18rem;
  font-weight: 400;
  color: #FFFFFF;
  padding-left: .2rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.newHead h4 {
  padding:0 .12rem;
  height: 100%;
  line-height: .42rem;
  font-size: .18rem;
  font-weight: 400;
  color: #FFFFFF;
  background: #0071B7;
}

.shuffling {
  width: 100%;
  height: 4.76rem;
}

.swiperImg {
  width: 100%;
  height: 4.76rem;
}
</style>

<style>
.swiper-pagination {
  height: .42rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  padding-right: .26rem;
  bottom: 0px !important;
  background: rgba(0,0,0,.6);
}

.swiper-pagination-bullet {
  background: #fff;
  width: 12px;
  height: 12px;
}

.swiper-pagination-bullet-active {
  background: #0071B7;
}
</style>