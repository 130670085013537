<!--
 * @Author: your name
 * @Date: 2021-10-10 10:15:18
 * @LastEditTime: 2022-05-06 09:53:54
 * @LastEditors: xiaoxuesheng 3832702@qq.com
 * @Description: In User Settings Edit
 * @FilePath: /guanwang/src/pages/home/components/institutionsComp.vue
-->
<template>
  <div class="instituions">
    <div class="instituions-l">
      <div @click="jumpEvent('organization', 'first')" class="instituions-box">
        <div class="imgBox">
          <img src="../../../common/images/icon01@2x.png" alt="" />
        </div>
        <div class="instituions-head-r">
          <span class="instituions-headTitle">热标委介绍</span>
          <span>INTRODUCE</span>
        </div>
      </div>
      <div @click="jumpEvent('organization', 'second')" class="instituions-box">
        <div class="imgBox">
          <img src="../../../common/images/icon02@2x.png" alt="" />
        </div>
        <div class="instituions-head-r">
          <span class="instituions-headTitle">热标委章程</span>
          <span>BYLAWS</span>
        </div>
      </div>
      <div @click="jumpEvent('organization', 'third')" class="instituions-box">
        <div class="imgBox">
          <img src="../../../common/images/icon03@2x.png" alt="" />
        </div>
        <div class="instituions-head-r">
          <span class="instituions-headTitle">热标委组成</span>
          <span>COMPOSITION</span>
        </div>
      </div>
      <div @click="jumpEvent('organization', 'fourth')" class="instituions-box">
        <div class="imgBox">
          <img src="../../../common/images/icon04@2x.png" alt="" />
        </div>
        <div class="instituions-head-r">
          <span class="instituions-headTitle">联系方式</span>
          <span>CONTACT</span>
        </div>
      </div>
    </div>
    <div class="instituions-l">
      <div
        v-for="(item, index) in sysUrl"
        :key="index"
        class="instituions-box"
        @click="jumpHandler(item)"
      >
        <img style="width: 100%;height: 100%;position: absolute;left: 0;top:0;" :src="require(`../../../common/images/log_bg_0${index + 1}.png`)" alt="">
        <div class="instituions-content">
          <span class="instituions-headTitle">{{ item.sysName }}</span>
          <span>{{ item.subTitle }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sysUrl: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {}
  },

  methods: {
    /**
     * @description: 跳转链接
     * @param {Object} item 
     * @return {*}
     */    
    jumpHandler(item) {
      window.open(item.url.indexOf('http') > -1 ? item.url: `http://${item.url}` )
    },

    jumpEvent(path, tag) {
      if (this.$route.name !== path) this.$router.push({ name: path, query: { tag } })
    }
  }
}
</script>

<style scoped>
.instituions {
  width: 100%;
  height: 1.5rem;
  box-sizing: border-box;
  border-top: none;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.3rem;
}

.instituions-l {
  width: calc(50% - 11px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.instituions-l .instituions-head-r {
  width: 0.9rem;
  text-align: left;
}

.instituions-l > div {
  width: calc(50% - 4px);
  height: 0.7rem;
  background: #d2efff;
}

.instituions-box {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor:pointer;
  position: relative;
}

.instituions-box .imgBox {
  height: 0.48rem;
  width: 0.48rem;
  border-radius: 50%;
  background: #fff;
  margin-right: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imgBox img {
  width: 0.3rem;
  height: 0.3rem;
}

.instituions-box .instituions-head-r {
  display: flex;
  flex-direction: column;
}

.instituions-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 0.15rem;
  align-items: center;
  color: #ffffff;
  position: absolute;
  left: 0;
}

.instituions-content .instituions-headTitle {
  font-size: 0.21rem;
}

.instituions-box .instituions-head-r span {
  font-size: 0.11rem;
  color: #919191;
  width: 100%;
  height: 0.18rem;
  line-height: 0.18rem;
  transform: scale(0.8);
  text-align: left;
  position: relative;
  left: -5px;
}

.instituions-box .instituions-head-r .instituions-headTitle {
  color: #000000;
  font-size: 0.18rem;
  height: 0.24rem;
  line-height: 0.24rem;
  transform: scale(1);
  left: 0;
}

.instituions-1 {
  background: url("../../../common/images/log_bg_01.png") no-repeat !important;
  background-size: cover;
  overflow: hidden;
  border-radius: 4px;
}

.instituions-2 {
  background: url("../../../common/images/log_bg_02.png") no-repeat !important;
  background-size: cover;
  overflow: hidden;
  border-radius: 4px;
}

.instituions-3 {
  background: url("../../../common/images/log_bg_03.png") no-repeat !important;
  background-size: cover;
  overflow: hidden;
  border-radius: 4px;
}

.instituions-4 {
  background: url("../../../common/images/log_bg_04.png") no-repeat !important;
  background-size: cover;
  overflow: hidden;
  border-radius: 4px;
}
</style>
