<template>
  <div class="content">
    <div class="contentHead">
      <h4></h4>
      <p>
        当前位置：首页>{{ `${title}`
        }}<span v-if="articlesVo.msgInfo">{{
          `(${articlesVo.msgInfo.split("@").join("")})`
        }}</span>
      </p>
    </div>
    <div class="detailsContent">
      <div class="head">
        <h5>{{ articlesVo.draftName }}</h5>
        <div class="tags">
          <el-tag size="mini" style="margin-right: 15px;" effect="dark">{{ articlesVo.standardType }}</el-tag>
          <el-tag v-if="articlesVo.portalStatus" size="mini" type="success" effect="dark">{{ articlesVo.portalStatusDesc }}</el-tag>
        </div>
      </div>
      <div class="status box1">
            <p>{{ articlesVo.line1 }}</p>
            <p>{{ articlesVo.line2 }}</p>
            <p>{{ articlesVo.line3 }}</p>
      </div>
      <el-divider content-position="left">标准状态</el-divider>
      <div class="status">
        <el-steps align-center :space="200" :active="-1" finish-status="success">
          <el-step :title="portalStatus ? `发布于 ${articlesVo.pubDate}`: '即将实施'"></el-step>
          <el-step :title="portalStatus && portalStatus !== 'Q01' ? `实施于 ${articlesVo.pimDate}`: '现行'"></el-step>
          <el-step :title="portalStatus && portalStatus !== 'Q01' && portalStatus !== 'Q02' ? `被替代于 ${articlesVo.bdtDate}`: '代替'" class="tidai"></el-step>
          <el-step :title="portalStatus && portalStatus !== 'Q01' && portalStatus !== 'Q02' && portalStatus !== 'Q03' ? `废止于 ${articlesVo.fzdate}`: '废止'"></el-step>
        </el-steps>
      </div>
      <el-divider content-position="left">基础信息</el-divider>
      <div class="status baseInfo">
        <p v-for="item in articlesVo.baseInfo">{{ item.fieldKey }}：{{ item.fieldValue }}</p>
      </div>
      <template v-if="articlesVo.dts && articlesVo.dts.length">
        <el-divider content-position="left">代替以下标准</el-divider>
        <div class="status company">
          <p v-for="item in articlesVo.dts">{{ item }}</p>
        </div>
      </template>
      <template v-if="articlesVo.bdts && articlesVo.bdts.length">
        <el-divider content-position="left">被以下标准代替</el-divider>
      <div class="status company">
        <p v-for="item in articlesVo.bdts">{{ item }}</p>
      </div>
      </template>
      <el-divider content-position="left">起草单位</el-divider>
      <div class="status company">
        <p v-for="item in articlesVo.unitNames">{{ item }}</p>
      </div>
      <el-divider content-position="left">起草人</el-divider>
      <div class="status people">
        <p v-for="item in articlesVo.userNames">{{ item }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "政策法规",
    },

    // 文章实体
    articlesVo: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      portalStatus: '',
      portalStatusDesc: ''
    }
  },

  mounted() {
    console.log("articlesVo", this.articlesVo)
  },

  methods: {},
}

</script>

<style scoped>

.head {
  width: calc(100% - 20px);
  margin-left: 20px !important;
  padding-left: 0 !important;
  padding-top: .2rem;
  border-bottom: 1px solid #DCDFE6;
}

.box1 {
  width: 100%;
  box-sizing: border-box;
  padding-left: 20px !important;
  padding-top: .25rem;
}

.box1 p{
  text-indent: 20px;
  font-size: .14rem;
  font-weight: 400;
}

.head h5 {
  font-size: 18px;
}

.tags {
  margin-bottom: 10px;
  padding-top: 2px;
}

.content {
  width: 10.52rem;
  min-height: 420px;
}

.contentHead {
  width: 100%;
  height: 0.6rem;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  border-bottom: 4px solid transparent;
  align-items: center;
  margin-bottom: 0.2rem;
}

.contentHead h4 {
  /* font-size: .22rem; */
  font-size: 0.19rem !important;
  font-weight: bold;
  color: #000000;
  padding: 0 0.24rem;
  position: relative;
  height: 100%;
  line-height: 0.56rem;
}

.contentHead p {
  font-size: 0.16rem;
  font-weight: 400;
  color: #777777;
}

.detailsContent {
  /* font-size: .22rem; */
  font-size: 0.18rem !important;
  font-weight: 400;
  color: #000000;
  line-height: 0.38rem;
  margin-bottom: 0.68rem;
}

.status {
  margin-bottom: 35px;
  width: 100%;
  min-height: 20px;
  box-sizing: border-box;
  padding-left: 40px;
}

.baseInfo {
  display: flex;
  flex-wrap: wrap;
  font-size: .14rem;
  color: #393838;
}

.baseInfo p {
  width: 50%;
  line-height: 36px;
}

.company {
  display: flex;
  flex-wrap: wrap;
}

.company p {
  width: 50%;
  line-height: 36px;
}

.people {
  display: flex;
  flex-wrap: wrap;
}

.people p{
  min-width: 100px;
  line-height: 36px;
}

.people p:nth-of-type(3n) {
    margin-right: 60px;
}
</style>

<style>
.el-divider__text.is-left {
  left: 0 !important;
  font-size: 18px;
}

.el-step {
  display: flex !important;
  flex-direction: column-reverse !important;
}

.el-step__title {
  font-size: 12px !important;
}

.el-step__icon-inner {
  display: none !important;
}

.el-step__icon {
  width: 16px !important;
  height: 16px !important;
}

.el-step__line {
  top: 10px !important;
}

.is-process .el-step__icon {
  background: #67C23A !important;
  border-color: #67C23A !important;
}

.tidai .el-step__main {
  position: relative;
  top: 48px;
}

/* .el-step__line {
  left: 0 !important;
} */
</style>
