/*
 * @Author: your name
 * @Date: 2021-01-13 23:19:01
 * @LastEditTime: 2021-12-04 10:38:51
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /guanwang/src/main.js
 */
import Vue from 'vue'
import App from './App.vue'
import VueRouter from "vue-router"
import routers from './router/index.js'
import './common/css/reset.css'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);

Vue.use(VueRouter)
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error=> error)
}

const router = new VueRouter({
  mode: 'history',
  routes: routers,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0}
  }
})


Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
