<!--
 * @Author: your name
 * @Date: 2021-01-13 23:19:01
 * @LastEditTime: 2023-02-21 14:02:44
 * @LastEditors: xiaoxuesheng 3832702@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /guanwang/src/components/footer.vue
-->
<template>
  <div class="footer">
      <div class="footer-box">
          <div class="footer-l">
            <p>热带作物及制品标准网</p>
            <span>Tropical Crops and Products </span>
            <a target="blank" href="https://beian.miit.gov.cn">琼ICP备2023000850号-1</a>
          </div>
          <div class="footer-r">
            <p style="display: flex;flex-wrap: wrap;">
              <span>Copyright © 2021, All Rights Reserved</span>
              <span>农业农村部热带作物及制品标准化技术委员会</span>
              <span style="height: 10px;"></span>
              <span>地址：海南省海口市龙华区城西学院路4号</span>
              <span>电话:0898-66962968</span>
          </p>
          </div>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.footer {
    width: 100%;
    min-width: 960px;
    height: 1.65rem;
    background: #0071B7;
    display: flex;
    align-items:center;
    justify-content: center;
}

.footer-box {
    width: 14rem;
    display: flex;
    align-items:center;
    justify-content: center;
}

.footer-box .footer-l {
  width: 50%;
  
  display: flex;
  align-items:center;
  flex-wrap: wrap;
  flex-direction: column;
}

.footer-box .footer-r {
  width: 50%;
  height: 100%;
  display: flex;
  align-items:center;
  justify-content: flex-end;
}

.footer-r p {
  width: 3.54rem;
  font-size: 0.14rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFFFFE;
  line-height: 0.18rem;
}

.footer-l p {
  font-family: Source Han Sans SC;
  font-weight: 800;
  color: #FFFFFF;
  font-size: 0.25rem;
  width:100%;
  margin-bottom: 0.05rem;
  line-height: 0.25rem;
  height: 0.25rem;
}

.footer-l span {
  width: 100%;
  font-family: Source Han Sans SC;
  font-weight: bold;
  color: #FFFFFF;
  font-size: 0.08rem;
  line-height: 0.08rem;
  height: 0.08rem;
}

.footer-l a {
  line-height: .24rem;
  width: 100%;
  font-size: .14rem;
  margin-top: 10px;
  color: #fff;
  cursor:pointer;
}

.footer-r span {
  line-height: .24rem;
  text-align:right;
  width: 100%;
  font-size: .14rem;
}

</style>