<template>
  <div class="boxContent">
    <Sidebar />
    <div v-if="currDetails.contacts" class="content">
      <div class="contentHead">
        <h4>联系我们</h4>
        <p>当前位置：首页>联系我们</p>
      </div>
      <div
        v-for="(item, index) in currDetails.contacts"
        :key="index"
        class="listContent"
      >
        <div style="width:50%;" class="listContent-l listContent-border">
          <h4
            v-for="(subItem, subIndex) in item.unitName.split('@')"
            :key="subIndex"
          >
            {{ subItem }}
          </h4>
          <p class="address">地址：{{ item.address }}</p>
          <p>邮编：{{ item.postal }}</p>
          <p>联系人：{{ item.person }}</p>
          <p>电话：{{ item.phone }}</p>
          <p>邮箱：{{ item.mailbox }}</p>
        </div>
        <div class="listContent-r">
          <!-- <p>
            <img :src="currDetails.pubImgs[0]" alt="" />
            <span>热标委公众号</span>
          </p>
          <p>
            <img :src="currDetails.pubImgs[1]" alt="" />
            <span>热科院公众号</span>
          </p> -->
          <el-image :src="currDetails.contacts[index].url" :preview-src-list="[currDetails.contacts[index].url]" alt="" />
        </div>
        <!-- <div class="listContentImgs">
          <img :src="currDetails.contacts[0].url" alt="" />
        </div> -->
      </div>
      <div v-if="currDetails.pubImgs" class="listContent-bottom">
        <p>
          <img :src="currDetails.pubImgs[0].url" alt="" />
          <span>{{ currDetails.pubImgs[0].name }}</span>
        </p>
        <p>
          <img :src="currDetails.pubImgs[1].url" alt="" />
          <span>{{ currDetails.pubImgs[1].name }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "../../components/sidebar"
import ListCompDown from "../../components/listCompDown"
import { getContactsDetails } from "../../api/api.js"
export default {
  components: {
    Sidebar,
    ListCompDown,
  },
  data() {
    return {
      activeName: "second",
      currDetails: {},
    }
  },
  async mounted() {
    try {
      this.currDetails = await getContactsDetails()
    } catch (e) {
      console.log(e)
    }
  },
  methods: {
    handleClick() {},
  },
}
</script>

<style scoped>
.boxContent {
  width: 14rem;
  min-width: 960px;
  display: flex;
  justify-content: space-between;
  min-height: 100vh;
  margin: 0 auto;
  padding-top: 0.2rem;
  overflow: hidden;
}

.content {
  width: calc(100% - 3.5rem);
  height: 100%;
  background: #f9f9f9;
  box-sizing: border-box;
}

.contentHead {
  width: 100%;
  height: 0.6rem;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  border-bottom: 4px solid #eef9ff;
  align-items: center;
  background: #fff;
  padding: 0 0.38rem;
}

.contentHead h4 {
  /* font-size: 0.22rem; */
  font-size: 0.19rem !important;
  font-weight: bold;
  color: #000000;
  padding: 0 0.24rem;
  position: relative;
  height: 100%;
  line-height: 0.56rem;
}

.contentHead h4:after {
  content: " ";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -4px;
  height: 4px;
  background: #1157af;
}

.contentHead p {
  font-size: 0.16rem;
  font-weight: 400;
  color: #777777;
}

.listContent {
  font-size: 0.18rem;
  color: #000;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding: 0.56rem 0.38rem;
  border-bottom: 1px dashed #e4e4e4;
  box-sizing: border-box;
}

.listContent-border {
  border-right: 1px solid #dcdee0;
}

.listContent-l {
  width: 90%;
  padding-bottom: 0.2rem;
  margin-bottom: 0.3rem;
  box-sizing: border-box;
}

.listContent-l h4 {
  font-size: 0.2rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #1157af;
  line-height: 0.3rem;
  width: 98%;
}

.listContent-l .address {
  margin-top: 0.26rem;
}

.listContent-l p {
  line-height: 0.28rem;
}

.listContent-r,
.listContent-bottom {
  width: 50%;
  height: 100%;
  display: flex;
  padding-bottom: 0.2rem;
  justify-content: center;
}

.listContent-bottom {
  width: 100%;
  padding: 100px 0;
}

.listContent-r p,
.listContent-bottom p {
  display: flex;
  flex-direction: column;
  margin-left: 0.68rem;
  text-align: center;
  line-height: 0.4rem;
  font-size: 0.16rem;
}

.listContent-bottom img {
  width: 1.7rem;
  height: 1.7rem;
}

.listContent-r div {
  width: 90%;
  height: 100%;
}

.listContentImgs {
  width: 100%;
  height: 3.35rem;
}

.listContentImgs img {
  width: 100%;
  height: 100%;
}
</style>
