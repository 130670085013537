<!--
 * @Author: your name
 * @Date: 2021-11-17 19:20:12
 * @LastEditTime: 2023-10-22 17:33:53
 * @LastEditors: xiaoxuesheng 3832702@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /guanwang/src/pages/map/index.vue
-->
<template>
  <div class="content">
    <div class="contentHead">
      <span></span>
      <p>当前位置：首页> 网站地图</p>
    </div>
    <div class="mapHead">网站地图/SITEMAP</div>
    <ul>
      <li class="mapFirst">组织机构</li>
      <li @click="jumpHandler('organization', { tag: 'first' })" class="mapItem">热标委介绍</li>
      <li @click="jumpHandler('organization', { tag: 'second' })" class="mapItem">热标委章程</li>
      <li @click="jumpHandler('organization', { tag: 'third' })" class="mapItem">热标委组成</li>
      <li @click="jumpHandler('organization', { tag: 'fourth' })" class="mapItem">联系我们</li>
      <li @click="jumpHandler('organization', { tag: 'fourth1' })" class="mapItem">其他平台</li>
    </ul>
    <ul>
      <li class="mapFirst">系统登录</li>
      <li class="mapItem">
        热带作物及制品标准化管理系统<span>（热标委）</span>
      </li>
      <li class="mapItem">
        农业行业标准制修订管理系统<span>（农业农村部）</span>
      </li>
    </ul>
    <ul>
      <li
        class="mapFirst"
        style="background:#fff;position:relative;bottom: -1px;"
      ></li>
      <li class="mapItem">国家标准化业务管理平台<span>（国标委）</span></li>
      <li class="mapItem">
        国家标准化工作平台<span>（ISO国内技术对口单位）</span>
      </li>
    </ul>
    <ul>
      <li class="mapFirst">通知公告</li>
      <li @click="jumpHandler('notice')" class="mapItem">通知公告</li>
    </ul>
    <ul>
      <li  class="mapFirst">征求意见</li>
      <li @click="jumpHandler('opinion')"  class="mapItem">征求意见</li>
    </ul>
    <ul>
      <li class="mapFirst">政策法规</li>
      <li @click="jumpHandler('rules')" class="mapItem">法律法规</li>
      <li @click="jumpHandler('policyFile')" class="mapItem">政策文件</li>
      <li @click="jumpHandler('unscramble')" class="mapItem">政策解读</li>
    </ul>
    <ul>
      <li class="mapFirst">统计查询</li>
      <li @click="jumpHandler('workDynamic')" class="mapItem">立项查询</li>
      <li @click="jumpHandler('training')" class="mapItem">标准查询</li>
    </ul>
    <ul>
      <li class="mapFirst">下载中心</li>
      <li @click="jumpHandler('download')" class="mapItem">下载中心</li>
    </ul>
    <ul>
      <li class="mapFirst">联系我们</li>
      <li @click="jumpHandler('contactUs')" class="mapItem">联系我们</li>
    </ul>
    <ul>
      <li class="mapFirst">新闻动态</li>
      <li class="mapItem">新闻动态</li>
    </ul>
    <ul>
      <li class="mapFirst">发布公告</li>
      <li class="mapItem">发布公告</li>
    </ul>
    <ul>
      <li class="mapFirst">标准解读</li>
      <li class="mapItem">标准解读</li>
    </ul>
    <ul>
      <li class="mapFirst">流程简介</li>
      <li class="mapItem">项目管理</li>
      <li class="mapItem">标准制修订</li>
      <li class="mapItem">宣贯实施</li>
      <li class="mapItem">标准复审</li>
      <li class="mapItem">专家申请</li>
    </ul>
    <ul>
      <li class="mapFirst">友情链接</li>
      <li class="mapItem">政府部门/组织</li>
      <li class="mapItem">服务平台</li>
      <li class="mapItem">标准出版社/物</li>
      <li class="mapItem">国内标准化研究机构</li>
      <li class="mapItem">国外标准化组织</li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },

  methods: {
    jumpHandler(path, query = {}) {
      if (this.$route.name !== path || this.$route.name === "organization")
        this.$router.push({ name: path, query })
    },
  },
}
</script>

<style scoped>
.content {
  width: 14rem;
  height: 100%;
  min-width: 960px;
  margin: 0 auto;
  margin-bottom: 0.3rem;
}

.contentHead {
  width: 100%;
  height: 0.6rem;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;
}

.contentHead h4 {
  /* font-size: 0.22rem; */
  font-size: .19rem !important;
  font-weight: bold;
  color: #000000;
  padding: 0 0.24rem;
  position: relative;
  height: 100%;
  line-height: 0.56rem;
}

.contentHead h4:after {
  content: " ";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -4px;
  height: 4px;
  background: #1157af;
}

.contentHead p {
  font-size: 0.16rem;
  font-weight: 400;
  color: #777777;
}

.mapHead {
  width: 100%;
  height: 0.8rem;
  background: url("../../common/images/sitemap_bg.png") no-repeat;
  background-size: cover;
  box-sizing: border-box;
  padding: 0 0.28rem;
  line-height: 0.8rem;
  /* font-size: 0.22rem; */
  font-size: .18rem !important;
  color: #1868b8;
  font-weight: bold;
  margin-bottom: 0.4rem;
}

ul {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  font-size: 0.18rem;
  border-bottom: 1px solid #ebf5ff;
  margin-bottom: 0.2rem;
}

li {
    cursor:pointer;
}

.mapFirst {
  width: 1.38rem;
  height: 0.5rem;
  background: #ebf5ff;
  color: #1868b8;
  font-weight: bold;
  font-size: 0.2rem;
  line-height: 0.5rem;
  text-align: center;
  border-radius: 2px;
}

.mapItem {
  padding: 0 0.3rem;
  color: #727171;
  height: 0.5rem;
  line-height: 0.5rem;
  position: relative;
}

.mapItem span {
  font-size: 0.16rem;
  color: #1868b8;
}

.mapItem:after {
  content: " ";
  width: 1px;
  height: 0.2rem;
  position: absolute;
  top: 0.15rem;
  right: 0;
  background: #929292;
}

.mapItem:last-child:after {
  width: 0;
}
</style>
