/*
 * @Author: your name
 * @Date: 2021-10-19 20:17:01
 * @LastEditTime: 2021-12-15 22:28:32
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /guanwang/src/api/index.js
 */
import axios from 'axios'
import Vue from 'vue'

axios.defaults.baseURL = window.baseUrl

if (process.env.NODE_ENV === 'production') {
	axios.defaults.baseURL = window.baseUrl
}

// request拦截器
axios.interceptors.request.use(
	config => {
		return config
	},
	error => {
		return Promise.reject(error)
	}
)

//response 拦截器
axios.interceptors.response.use(
  response => {
		const { data, data: { code, page, msg }, config } = response;
		
		if (config?.responseType === "blob") {
			return Promise.resolve(response);
		}
        
		// 根据返回状态判断是否出错
		if (code === 0) {
			return Promise.resolve(data);
		} else {
			return Promise.reject(msg)
		}
  },
  error => {
    return Promise.reject(error)
  }
)

/**
 * @description: get 请求 
 * @param {String} url 
 * @param {Object} params 参数
 * @param {Object} headers 请求首部
 * @return {*}
 */
export const fetch = function(url, params = {}, headers = {}, obj = {}) {
	return new Promise((resolve, reject) => {
		axios.get(
			url,
			{
				params,
				headers,
				...obj
			}
		)
			.then(res => {
				if (obj.responseType === 'blob') {
					resolve(res)
					return;
				}
				resolve(res.data ? res.data: res)
			})
			.catch(err => {
				reject(err)
			})
	})
}

/**
 * @description: post 请求 
 * @param {String} url 请求地址
 * @param {Object} data 请求参数
 * @param {Object} headers 请求首部
 * @return {*}
 */
export const post = function(url, data = {}, headers = {}) {
	return new Promise((resolve, reject) => {
		axios.post(url, data, { headers })
			.then(res => {
				resolve(res.data ? res.data: res)
			})
			.catch(err => {
				reject(err)
			})
	})
}