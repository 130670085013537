<!--
 * @Author: your name
 * @Date: 2021-01-13 23:19:01
 * @LastEditTime: 2023-03-26 11:28:16
 * @LastEditors: xiaoxuesheng 3832702@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /guanwang/src/App.vue
-->
<template>
  <div id="app">
    <Heade :isNotice="isNotice"></Heade>
    <router-view></router-view>
    <Foote></Foote>
  </div>
</template>


<script>
import Heade from "@/components/header.vue"
import Foote from "@/components/footer.vue"

export default {
  components: {
    Heade,
    Foote
  },
  watch: {
    $route(to) {
      if (to.name == 'home') {
        this.isNotice = true
      } else {
        this.isNotice = false
      }
      console.log('to', to)
    }
  },
  data() {
    return {
      isNotice: true
    }
  },
  mounted() {
    if (this.$route.name == 'home') {
        this.isNotice = true
      } else {
        this.isNotice = false
      }
  }
}
</script>

<style>
#app {
  font-family: Source Han Sans SC;
  min-width: 14rem;
  min-height: 100vh;
}

.box {
  width: 100%;
  min-width: 960px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow-x: auto;
}

.homeBox {
  width: 100%;
  min-width: 960px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: 100%;
}
</style>