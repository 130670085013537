<!--
 * @Author: your name
 * @Date: 2021-01-13 23:19:01
 * @LastEditTime: 2023-10-09 20:53:47
 * @LastEditors: xiaoxuesheng 3832702@qq.com
 * @Description: In User Settings Edit
 * @FilePath: /guanwang/src/pages/home/index.vue
-->
<template>
  <div class="homeBox">
    <div class="headernotice">
      <div class="headertip">
        <img src="../../common/images/publice.png" alt="" />
        通知
      </div>
      <div class="headerContent">
        <!-- <ul>
          <li
            class="anim"
            @click="jumpHandler(item)"
            v-for="(item, index) in indexDetails.redHeadFile"
            :key="index"
          >
            {{ item.title }}
          </li>
        </ul> -->
        <Notice v-if="indexDetails.redHeadFile" :message="indexDetails.redHeadFile" />
      </div>
    </div>
    <div class="layer">
      <NewsComp :scrollImgs="indexDetails.scrollImgs" class="layer_1" />
      <DynamicComp :newsDync="indexDetails.newsDync" class="DynamicComp" />
    </div>
    <InstitutionsComp :sysUrl="indexDetails.sysUrl" class="InstitutionsComp" />
    <div class="layer layer1">
      <NoticeComp :noticeMsg="[indexDetails.noticeMsg, indexDetails.opinMsg]" class="layer_1" />
      <RuleComp :policyLaw="[indexDetails.policyLaw, indexDetails.policyDoc, indexDetails.policyRead]" class="layer_1" />
    </div>
    <div class="layer layer1">
      <ColumnComp
        :standardNotice="[indexDetails.standardNotice, indexDetails.standardRead]"
        class="layer_2"
      />
      <Administration class="layer_2" />
    </div>
    <Adv />
    <LinkComp :indexDetails="indexDetails" class="layer1" />
  </div>
</template>

<script>
import NoticeComp from "./components/noticeComp"
import NewsComp from "./components/newsComp"
import InstitutionsComp from "./components/institutionsComp"
import DynamicComp from "./components/dynamicComp"
import RuleComp from "./components/ruleComp"
import Adv from "./components/adv"
import ColumnComp from "./components/columnComp"
import Administration from "./components/administration.vue"
import LinkComp from "./components/linkComp.vue"
import Notice from "../../components/noticeComp.vue"

import { getIndexDetails } from "../../api/api"

export default {
  components: {
    NoticeComp,
    NewsComp,
    DynamicComp,
    RuleComp,
    InstitutionsComp,
    Adv,
    ColumnComp,
    LinkComp,
    Administration,
    Notice
  },
  data() {
    return {
      indexDetails: {},
      animate: false,
    }
  },
  async mounted() {
    try {
      this.indexDetails = await getIndexDetails()
      console.log(this.indexDetails)
    } catch (e) {
      console.log(e)
    }
    
  },

  methods: {
    jumpHandler(item) {
      this.$router.push({ name: "details", query: { id: item.id } })
    },
  },
}
</script>

<style scoped>
.anim {
  transition: all 5s;
  margin-top: -30px;
}

.layer {
  width: 14rem;
  min-width: 960px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.3rem;
}

.layer1 {
  width: 14rem;
  min-width: 960px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.1rem;
}

.layer_1 {
  width: calc(50% - 11px);
}

.layer_2 {
  width: calc(50% - 10px);
}

.InstitutionsComp {
  width: 14rem;
  min-width: 960px;
}

.DynamicComp {
  width: calc(50% - 11px);
}

.RuleComp {
  width: 4.72rem;
}

.headernotice {
  width: 14rem;
  min-width: 960px;
  height: 50px;
  min-height: .5rem;
  font-size:16px !important;
  color: #000;
  display: flex;
  margin: 0 auto;
  align-items: center;
}

.headernotice .headertip {
  height: 100%;
  display: flex;
  align-items: center;
  width: .85rem;
  min-width: 85px;
  font-size: .2rem !important;
}

.headertip img {
  width: .22rem;
  height: .18rem;
  margin-right: 0.1rem;
}

.headernotice .headerContent {
  width: calc(100% - .85rem);
  max-width: calc(100% - 85px);
  overflow: hidden;
}
</style>
