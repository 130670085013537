<!--
 * @Author: your name
 * @Date: 2021-11-17 19:20:12
 * @LastEditTime: 2023-02-21 13:47:51
 * @LastEditors: xiaoxuesheng 3832702@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /guanwang/src/pages/map/index.vue
-->
<template>
  <div class="boxContent">
    <Sidebar />
    <ListCompDown :list="list" :count="count" />
  </div>
</template>

<script>
import Sidebar from "../../components/sidebar"
import ListCompDown from "../../components/listCompDown"

import { getDowncenterList } from "../../api/api.js"
export default {
  components: {
    Sidebar,
    ListCompDown
  },
  data() {
    return {
      page: 1,
      list: [],
      count: 0,
      limit: 20
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    /**
     * @description: 获取文章列表
     */
    async getList() {
      this.list = []
      this.count = 0
      try {
        const { page, limit } = this
        const { list, totalCount } = await getDowncenterList({
          page,
          limit,
          title: this.title,
        })
        this.list = list
        this.count = totalCount
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>

<style scoped>

.boxContent {
  width: 14rem;
  display: flex;
  justify-content: space-between;
  height: 100%;
  min-height: 100vh;
  margin: 0 auto;
  padding-top: 0.2rem;
}

</style>