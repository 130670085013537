<!--
 * @Author: your name
 * @Date: 2021-11-17 19:20:12
 * @LastEditTime: 2023-02-21 13:53:07
 * @LastEditors: xiaoxuesheng 3832702@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /guanwang/src/pages/map/index.vue
-->
<template>
  <div class="content">
    <Sidebar ref="sidebarComp" />
    <div class="contentHead">
      <el-tabs class="orgainTabs" v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="热标委介绍" name="first">
          <div class="detailsTitle">
            {{ introduce.title }}
          </div>
          <div class="more">
            <span>作者： {{ introduce.pubUser }}</span>
            <span>来源： {{ introduce.source }}</span>
            <span>日期： {{ introduce.pubTime }}</span>
            <span>点击： {{ introduce.viewnum }}</span>
          </div>
          <div class="introduce-item" v-html="introduce.introduce"></div>
        </el-tab-pane>
        <el-tab-pane label="热标委章程" name="second">
          <div class="detailsTitle">
            {{ constitution.title }}
          </div>
          <div class="more">
            <span>作者： {{ constitution.pubUser }}</span>
            <span>来源： {{ constitution.source }}</span>
            <span>日期： {{ constitution.pubTime }}</span>
            <span>点击： {{ constitution.viewnum }}</span>
          </div>
          <div class="introduce-item" v-html="constitution.introduce"></div>
        </el-tab-pane>
        <el-tab-pane label="热标委组成" name="third">
          <!-- <div v-if="standpersonInfo.honorary" class="ranks">
            <div
              v-for="(item, index) in standpersonInfo.honorary"
              :key="index"
              class="ranks1"
            >
              <img :src="item.photo" alt="" />
              <div class="ranksInfo">
                <h5>{{ item.name }}</h5>
                <p
                  v-for="(subItem, subIndex) in item.job.split('@')"
                  :key="subIndex"
                >
                  {{ subItem }}
                </p>
              </div>
            </div>
            <div></div>
          </div>
          <div v-if="standpersonInfo.chairman" class="ranks">
            <div
              v-for="(item, index) in standpersonInfo.chairman"
              :key="index"
              class="ranks1"
            >
              <img :src="item.photo" alt="" />
              <div class="ranksInfo">
                <h5>{{ item.name }}</h5>
                <p
                  v-for="(subItem, subIndex) in item.job.split('@')"
                  :key="subIndex"
                >
                  {{ subItem }}
                </p>
              </div>
            </div>
            <div></div>
          </div>
          <ul v-if="standpersonInfo.viceChairman" class="imgs">
            <li
              v-for="(item, index) in standpersonInfo.viceChairman"
              :key="index"
            >
              <img :src="item.photo" alt="" />
              <h6>{{ item.name }}</h6>
              <p
                v-for="(subItem, subIndex) in item.job.split('@')"
                :key="subIndex"
              >
                {{ subItem }}
              </p>
            </li>
          </ul>
          <ul v-if="standpersonInfo.secretary" class="imgs">
            <li v-for="(item, index) in standpersonInfo.secretary" :key="index">
              <img :src="item.photo" alt="" />
              <h6>{{ item.name }}</h6>
              <p
                v-for="(subItem, subIndex) in item.job.split('@')"
                :key="subIndex"
              >
                {{ subItem }}
              </p>
            </li>
          </ul>
          <ul v-if="standpersonInfo.underSecretary" class="imgs">
            <li
              v-for="(item, index) in standpersonInfo.underSecretary"
              :key="index"
            >
              <img :src="item.photo" alt="" />
              <h6>{{ item.name }}</h6>
              <p
                v-for="(subItem, subIndex) in item.job.split('@')"
                :key="subIndex"
              >
                {{ subItem }}
              </p>
            </li>
          </ul> -->
          <div class="ranks">
            <div v-if="standpersonInfo.honorary" class="ranks-item">
              <div class="ranks-label">
                名誉主任
              </div>
              <ul class="ranks-value">
                <li
                  style="width: 90%;"
                  v-for="(item, index) in standpersonInfo.honorary"
                  :key="index"
                >
                  <h6>{{ item.name }}</h6>
                  <p
                    v-for="(subItem, subIndex) in item.job.split('@')"
                    :key="subIndex"
                  >
                    {{ subItem }}
                  </p>
                </li>
              </ul>
            </div>
            <div v-if="standpersonInfo.chairman" class="ranks">
              <div class="ranks-item">
                <div class="ranks-label">
                  主任委员
                </div>
                <ul class="ranks-value">
                  <li
                    style="width: 90%;"
                    v-for="(item, index) in standpersonInfo.chairman"
                    :key="index"
                  >
                    <h6>{{ item.name }}</h6>
                    <p
                      v-for="(subItem, subIndex) in item.job.split('@')"
                      :key="subIndex"
                    >
                      {{ subItem }}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div v-if="standpersonInfo.viceChairman" class="ranks">
              <div class="ranks-item">
                <div class="ranks-label">
                  副主任委员
                </div>
                <ul class="ranks-value">
                  <li
                    style="width: 33%;padding: .2rem 0;"
                    v-for="(item, index) in standpersonInfo.viceChairman"
                    :key="index"
                  >
                    <h6>{{ item.name }}</h6>
                    <p
                      v-for="(subItem, subIndex) in item.job.split('@')"
                      :key="subIndex"
                    >
                      {{ subItem }}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div v-if="standpersonInfo.secretary" class="ranks">
              <div class="ranks-item">
                <div class="ranks-label">
                  秘书长
                </div>
                <ul class="ranks-value">
                  <li
                    style="width: 33%;padding: .2rem 0;"
                    v-for="(item, index) in standpersonInfo.secretary"
                    :key="index"
                  >
                    <h6>{{ item.name }}</h6>
                    <p
                      v-for="(subItem, subIndex) in item.job.split('@')"
                      :key="subIndex"
                    >
                      {{ subItem }}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="联系我们" name="fourth">
          <template v-if="contacDetails">
            <div
              v-for="(item, index) in contacDetails.contacts"
              :key="index"
              class="listContent"
            >
              <div style="width:50%;" class="listContent-l listContent-border">
                <h5
                  v-for="(subItem, subIndex) in item.unitName.split('@')"
                  :key="subIndex"
                >
                  {{ subItem }}
                </h5>
                <p class="address">地址：{{ item.address }}</p>
                <p>邮编：{{ item.postal }}</p>
                <p>联系人：{{ item.person }}</p>
                <p>电话：{{ item.phone }}</p>
                <p>邮箱：{{ item.mailbox }}</p>
              </div>
              <div class="listContent-r">
                <el-image
                  :src="contacDetails.contacts[index].url"
                  :preview-src-list="[contacDetails.contacts[index].url]"
                  alt=""
                />
              </div>
            </div>
            <div v-if="contacDetails.pubImgs" class="listContent-bottom">
              <p>
                <img :src="contacDetails.pubImgs[0].url" alt="" />
                <span>{{ contacDetails.pubImgs[0].name }}</span>
              </p>
              <p>
                <img :src="contacDetails.pubImgs[1].url" alt="" />
                <span>{{ contacDetails.pubImgs[1].name }}</span>
              </p>
            </div>
          </template>
        </el-tab-pane>
        <el-tab-pane label="其他平台" name="fourth1">
          <div class="otherBox">
            <div
              @click="jumpHandler(item)"
              v-for="(item, index) in otherplatInfo.otherPaltUrl"
              :key="index"
              class="other"
            >
              <span
                v-for="(subItem, subIndex) in item.sysName.split('@')"
                :key="subIndex"
              >
                {{ subItem }}
              </span>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <p>当前位置：首页> 组织结构</p>
    </div>
  </div>
</template>

<script>
import Sidebar from "../../components/sidebar"
import ListCompDown from "../../components/listCompDown"
import {
  getContactsDetails,
  getStandpersonInfo,
  getIntroduce,
  getConstitution,
  getOtherplatInfo,
} from "../../api/api.js"
import { getDowncenter } from "../../api/api"
export default {
  components: {
    Sidebar,
    ListCompDown,
  },
  data() {
    return {
      activeName: "first",
      contacDetails: null, // 联系我们详情
      standpersonInfo: {}, // 热标委组成
      introduce: {}, // 热标委介绍
      constitution: {}, // 热标委章程
      otherplatInfo: {}, // 其他
    }
  },
  watch: {
    $route(to) {
      //console.log('to', to)
      this.activeName = to.query.tag
    },
  },
  mounted() {
    this.getContacDetails()
    this.getIntroduce()
    this.getStandpersonInfo()
    this.getOtherplatInfo()
    this.getConstitution()
    this.activeName = this.$route.query.tag
  },
  methods: {
    handleClick() {
      this.$refs.sidebarComp.switchHandler(this.activeName)
    },

    /**
     * @description: 获取文章列表
     */
    async getConstitution() {
      try {
        this.constitution = await getConstitution()
      } catch (e) {
        console.log(e)
      }
    },

    jumpHandler(item = {}) {
      window.open(
        window.location.origin + "/details" + "?id=" + item.id + "&type=111",
        "_blank"
      )
      // window.open(
      //   item.url.indexOf("http") > -1 ? item.url : `http://${item.url}`
      // )
    },

    /**
     * @description: 获取联系我们详情
     * @param {*}
     * @return {*}
     */

    async getContacDetails() {
      try {
        this.contacDetails = await getContactsDetails()
      } catch (e) {
        console.log(e)
      }
    },

    /**
     * @description: 获取热标委组成
     * @param {*}
     * @return {*}
     */

    async getStandpersonInfo() {
      try {
        this.standpersonInfo = await getStandpersonInfo()
      } catch (e) {
        console.log(e)
      }
    },

    /**
     * @description: 获取热标委组成
     * @param {*}
     * @return {*}
     */

    async getStandpersonInfo() {
      try {
        this.standpersonInfo = await getStandpersonInfo()
      } catch (e) {
        console.log(e)
      }
    },

    /**
     * @description: 获取热标委组成
     * @param {*}
     * @return {*}
     */

    async getStandpersonInfo() {
      try {
        this.standpersonInfo = await getStandpersonInfo()
      } catch (e) {
        console.log(e)
      }
    },

    /**
     * @description: 获取其他
     * @param {*}
     * @return {*}
     */

    async getOtherplatInfo() {
      try {
        this.otherplatInfo = await getOtherplatInfo()
      } catch (e) {
        console.log(e)
      }
    },

    /**
     * @description: 获取热标委介绍
     * @param {*}
     * @return {*}
     */

    async getIntroduce() {
      try {
        this.introduce = await getIntroduce()
      } catch (e) {
        console.log(e)
      }
    },

    /**
     * @description:  下载
     */
    // async downHandler(item) {
    //   if (!item.url) {
    //     return
    //   }
    //   const res = await getDowncenter(item.url)
    //   let url = URL.createObjectURL(new Blob([res.data]))
    //   let a = document.createElement("a")
    //   document.body.appendChild(a)
    //   a.href = url
    //   let arr = item.url.split(".")
    //   a.download = `${item.fileName}.${arr[arr.length - 1]}`
    //   a.target = "_blank"
    //   a.click()
    //   a.remove()
    // }
  },
}
</script>

<style scoped>
.detailsTitle {
  font-size: 0.25rem;
  font-weight: bold;
  color: #000000;
  text-align: center;
  line-height: 0.38rem;
  margin-bottom: 0.2rem;
  margin-top: 0.2rem;
}

.more {
  width: 100%;
  height: 0.38rem;
  display: flex;
  align-items: center;
  border-bottom: 1px dashed #777;
  margin-bottom: 0.26rem;
  justify-content: center;
}

.more span {
  margin-right: 0.5rem;
  font-size: 0.16rem;
  font-weight: 400;
  color: #777777;
}

.introduceClass {
  font-size: 0.2rem;
  text-align: center;
  color: #000;
  line-height: 0.6rem;
  height: 0.6rem;
}

.content {
  width: 14rem;
  height: 100%;
  min-width: 960px;
  margin: 0 auto;
  margin-bottom: 0.3rem;
  display: flex;
  justify-content: space-between;
  padding-top: 0.2rem;
  overflow: hidden;
}

.contentHead {
  width: 100%;
  width: calc(100% - 3.6rem);
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;
  position: relative;
  color: #000;
}

.contentHead h4 {
  /* font-size: 0.22rem; */
  font-size: 0.19rem !important;
  font-weight: bold;
  color: #000000;
  padding: 0 0.24rem;
  position: relative;
  height: 100%;
  line-height: 0.56rem;
}

.contentHead h4:after {
  content: " ";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -4px;
  height: 4px;
  background: #1157af;
}

.contentHead > p {
  font-size: 0.16rem;
  font-weight: 400;
  color: #777777;
  position: absolute;
  right: 0;
  top: 10px;
}

.orgainTabs {
  width: 100%;
  /* font-size: 0.22rem; */
  font-size: 0.18rem !important;
  color: #1157af;
  height: 100%;
}

.introduce-item {
  width: 94%;
  margin: 0 auto;
  color: #000;
}

.introduce-item p {
  font-family: Microsoft YaHei;
  font-size: 0.2rem;
  color: #000000;
  line-height: 0.5rem;
  text-indent: 0.5rem;
}

.ranks {
  width: 100%;
  height: 100%;
}

.ranks .ranks-item {
  background: #eef9ff;
  padding: 30px 0;
  position: relative;
  display: flex;
  align-items: center;
}

.ranks-item:after {
  content: " ";
  height: 1px;
  background: #d2efff;
  position: absolute;
  left: 10px;
  right: 10px;
  bottom: 0;
}

.ranks-label {
  width: 20%;
  color: #000000;
  text-align: center;
  font-weight: bold;
  font-size: 0.2rem;
}

.ranks-value {
  width: 80%;
  color: #000000;
  display: flex;
  flex-wrap: wrap;
}

.ranks-value h6 {
  font-size: 0.2rem;
  font-weight: bold;
  height: 0.5rem;
  line-height: 0.5rem;
}

.ranks-value p {
  font-size: 0.16rem;
  line-height: 0.3rem;
  width: 90%;
}

.ranks1 {
  width: 100%;
  height: 2.9rem;
  background: #eef9ff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0 0.2rem;
  border-bottom: 1px solid #d2efff;
}

.ranks1 img {
  width: 1.86rem;
  height: 2.68rem;
}

.ranks1 .ranksInfo {
  width: calc(100% - 2.1rem);
}

.ranksInfo h5 {
  /* font-size: 0.22rem; */
  font-size: 0.18rem !important;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #000000;
  margin-bottom: 0.6rem;
}

.ranksInfo p {
  font-size: 0.18rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  line-height: 0.3rem;
}

.imgs {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background: #eef9ff;
  padding-top: 0.8rem;
  box-sizing: border-box;
  padding-left: 0.18rem;
}

.imgs li {
  width: 1.5rem;
  height: 2.9rem;
  margin-left: 0.64rem;
  margin-bottom: 0.6rem;
}

.imgs li:first-child {
  margin: 0;
}

.imgs li:nth-child(5n + 1) {
  margin: 0;
}

.imgs li img {
  width: 1.5rem;
  height: 2.17rem;
}

.imgs li p {
  width: 100%;
  text-align: center;
  color: #000;
  font-size: 0.18rem;
}

.imgs li h6 {
  width: 100%;
  height: 0.3rem;
  text-align: center;
  color: #000;
  /* font-size: 0.22rem; */
  font-size: 0.18rem !important;
}

.otherBox {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: 0.48rem;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.other {
  width: 4.55rem;
  height: 1.62rem;
  background: #eef9ff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-right: 0.3rem;
  margin-bottom: 20px;
  cursor: pointer;
  box-sizing: border-box;
  padding: 0 10px;
}

.other:hover {
  background: #1157af;
  color: #fff;
}

.other:hover span {
  color: #fff;
}

.other span {
  font-size: 0.2rem;
  color: #1157af;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
}

.listContent {
  font-size: 0.18rem;
  color: #000;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding: 0.56rem 0;
  border-bottom: 1px dashed #e4e4e4;
}

.listContent-border {
  border-right: 1px solid #dcdee0;
}

.listContent-l {
  width: 90%;
  padding-bottom: 0.2rem;
  margin-bottom: 0.3rem;
  box-sizing: border-box;
}

.listContent-l h5 {
  font-size: 0.2rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #1157af;
  line-height: 0.3rem;
  width: 100%;
}

.listContent-l .address {
  margin-top: 0.26rem;
}

.listContent-l p {
  line-height: 0.28rem;
}

.listContent-r,
.listContent-bottom {
  width: 50%;
  height: 100%;
  display: flex;
  padding-bottom: 0.2rem;
  justify-content: center;
}

.listContent-bottom {
  width: 100%;
  padding: 100px 0;
}

.listContent-r p,
.listContent-bottom p {
  display: flex;
  flex-direction: column;
  margin-left: 0.68rem;
  text-align: center;
  line-height: 0.4rem;
  font-size: 0.16rem;
  color: #000;
}

.listContent-bottom img {
  width: 1.7rem;
  height: 1.7rem;
}

.listContent-r div {
  width: 90%;
  height: 100%;
}

.listContentImgs {
  width: 100%;
  height: 3.35rem;
}

.listContentImgs img {
  width: 100%;
  height: 100%;
}
</style>

<style>
.orgainTabs .el-tabs__item {
  font-size: 0.19rem !important;
}
</style>
