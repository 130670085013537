<template>
  <div class="content">
    <div class="contentHead">
      <h4>{{ title }}</h4>
      <p>当前位置：首页>{{ title }}</p>
    </div>
    <div class="listContent">
      <el-form :model="form" class="listForm" label-width="90px" size="small">
        <el-form-item label="项目名称：">
          <el-input v-model="form.draftName" placeholder="标准名称"></el-input>
        </el-form-item>
        <el-form-item label="标准号：">
          <el-input v-model="form.draftNo" placeholder="标准号"></el-input>
        </el-form-item>
        <el-form-item label="发布年度：">
          <el-input v-model="form.pubYear" placeholder="发布年度"></el-input>
        </el-form-item>
        <el-form-item label="起草单位：">
          <el-input v-model="form.unitName" placeholder="起草单位"></el-input>
        </el-form-item>
        <el-form-item label="起草人：">
          <el-input v-model="form.fzr" placeholder="起草人"></el-input>
        </el-form-item>
        <el-form-item label="标准状态：">
          <el-select clearable style="width: 167px" v-model="form.status" placeholder="请选择">
            <el-option label="即将实施" value="Q01"></el-option>
            <el-option label="现行" value="Q02"></el-option>
            <el-option label="被代替" value="Q03"></el-option>
            <el-option label="作废" value="Q04"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label-width="20px">
          <el-button @click="queryHandler" type="primary">查询</el-button>
        </el-form-item>
      </el-form>

      <el-table :data="list" border @row-click="jumpDetails">
        <el-table-column
          min-width="50"
          prop="xh"
          label="序号"
        ></el-table-column>
        <el-table-column min-width="120" prop="draftNo" label="标准号">
          <template slot-scope="scope">
            <span style="color: #409EFF;cursor:pointer;">{{ scope.row.draftNo }}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="120" prop="draftName" label="标准名称"></el-table-column>
        <el-table-column min-width="100" prop="pubDate" label="发布日期"></el-table-column>
        <el-table-column
          min-width="100"
          prop="pimDate"
          label="实施日期"
        ></el-table-column>
        <el-table-column min-width="100" prop="status" label="状态"></el-table-column>
      </el-table>
    </div>
    <el-pagination
      background
      :page-size="limit"
      @size-change="sizeChangeHandler"
      @current-change="currentChangeHaddler"
      :current-page="page"
      :total="count"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    // 标题
    title: {
      type: String,
      default: "政策法规",
    },

    // 列表
    list: {
      type: Array,
      default() {
        return []
      },
    },

    // 数量
    count: {
      type: Number,
      default: 0,
    },

    isSearch: {
      type: Boolean,
      default: false,
    },

    // 每页数量
    limit: {
      type: Number,
      default: 10, // 每页显示条数
    },

    // 当前页
    page: {
      type: Number,
      default: 1,
    },

    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      form: {
        draftName: "",
        draftNo: "",
        pubYear: "",
        status: "",
        fzr: "",
        unitName: ""
      }
    }
  },

  methods: {
    /**
     * @description:  跳转详情
     */

    jumpDetails(e) {
      window.open(
        window.location.origin +
          "/querydetails" +
          "?id=" +
          e.id +
          "&type=" +
          this.type,
        "_blank"
      )
    },

    /**
     * @description: pageSize 改变时会触发
     * @param {Number} num 每页数量
     * @return {*}
     */

    sizeChangehandler(num) {
      this.$emit("update:limit", num)
    },

    queryHandler() {
        this.$emit("getList", this.form)
    },

    searchHandler() {
      this.$emit("update:page", 1)
      this.$emit("getList", this.form)
    },

    /**
     * @description: pageSize 改变时会触发
     * @param {Number} num 每页数量
     * @return {*}
     */

    sizeChangeHandler(num) {
      this.$emit("update:limit", num)
      this.$emit("getList", this.form)
    },

    /**
     * @description: currentPage 改变时会触发
     * @param {Number} num 当前页
     * @return {*}
     */

    currentChangeHaddler(num) {
      this.$emit("update:page", num)
      this.$emit("getList", this.form)
    },
  },
}
</script>

<style scoped>
.content {
  width: calc(100% - 3.5rem);
  height: 100%;
}

.listForm {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0;
}

.contentHead {
  width: 100%;
  height: 0.6rem;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  border-bottom: 4px solid #eef9ff;
  align-items: center;
}

.contentHead h4 {
  /* font-size: .22rem; */
  font-size: 0.19rem !important;
  font-weight: bold;
  color: #000000;
  padding: 0 0.24rem;
  position: relative;
  height: 100%;
  line-height: 0.56rem;
}

.contentHead h4:after {
  content: " ";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -4px;
  height: 4px;
  background: #1157af;
}

.contentHead p {
  font-size: 0.16rem;
  font-weight: 400;
  color: #777777;
}

.listContent {
  width: 100%;
  height: calc(100% - 1.4rem);
  margin-bottom: 0.2rem;
  overflow-y: auto;
  min-height: calc(100vh - 1.4rem);
}

.listContent ul {
  width: 100%;
  padding-top: 0.24rem;
}

.listContent li {
  /* font-size: .22rem; */
  font-size: 0.18rem !important;
  font-weight: 400;
  color: #000000;
  display: flex;
  justify-content: space-between;
  height: 0.6rem;
  line-height: 0.6rem;
  box-sizing: border-box;
  border-bottom: 1px dashed #777777;
  cursor: pointer;
}

.listContent span {
  width: 1.5rem;
  text-align: right;
}

.listContent p {
  width: calc(100% - 1.5rem);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  text-indent: 0.16rem;
  cursor: pointer;
  font-size: 0.18rem !important;
}

.listContent p:after {
  content: " ";
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background: #000;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -2px;
}
</style>

<style>
.content .active {
  background: #0071b7 !important;
}
</style>
