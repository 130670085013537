<!--
 * @Author: your name
 * @Date: 2021-10-10 12:11:22
 * @LastEditTime: 2021-11-17 23:50:04
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /guanwang/src/pages/process/index.vue
-->
<template>
  <div class="box">
    <div class="boxContent">
      <Sidebar />
      <ListComp title="业务流程" />
    </div>
  </div>
</template>

<script>
import Sidebar from "../../components/sidebar"
import ListComp from "../../components/listComp"

export default {
  components: {
    Sidebar,
    ListComp
  },
  data() {
    return {
    }
  }
}
</script>

<style scoped>

.boxContent {
  width: 14rem;
  display: flex;
  justify-content: space-between;
  height: 100%;
  min-height: 100vh;
  margin: 0 auto;
  min-width: 960px;
  padding-top: 0.2rem;
}

</style>