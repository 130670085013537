<template>
  <div>
    <div class="header">
      <div class="header-box">
        <div class="header-l">
          <h3>热带作物及制品标准网</h3>
          <p>Tropical Crops and Products </p>
        </div>
        <div class="header-r">
          <div class="header-login">
            <span @click="jumpMapHandler" style="margin-right: 28px;">网站地图</span>
            <a target="_blank" :href="loginUrl">登录</a>
            |
            <a target="_blank" :href="registerUrl">注册</a>
          </div>
          <div class="header-search">
            <el-input class="header-input" v-model="text" placeholder="请输入内容"></el-input>
            <span @click="searchHandler">搜索</span>
          </div>
        </div>
      </div>
    </div>
    <div class="headerTab">
      <el-menu
          :default-active="activeIndex"
          mode="horizontal"
          class="header-menu"
          background-color="transparent"
          text-color="#fff"
          active-text-color="#fff">
        <el-menu-item @click="jumpHandler('home')" index="0">首页</el-menu-item>
        <el-submenu index="1">
          <template slot="title">组织机构</template>
          <el-menu-item @click="jumpHandler('organization', { tag: 'first' })" index="1-1">热标委介绍</el-menu-item>
          <el-menu-item @click="jumpHandler('organization', { tag: 'second' })" index="1-2">热标委章程</el-menu-item>
          <el-menu-item @click="jumpHandler('organization', { tag: 'third' })" index="1-3">热标委组成</el-menu-item>
          <el-menu-item @click="jumpHandler('organization', { tag: 'fourth' })" index="1-3">联系我们</el-menu-item>
          <el-menu-item @click="jumpHandler('organization', { tag: 'fourth1' })" index="1-3">其他平台</el-menu-item>
        </el-submenu>
        <el-menu-item @click="jumpHandler('notice')" index="7">通知公告</el-menu-item>
         <el-menu-item @click="jumpHandler('opinion')" index="8">征求意见</el-menu-item>
        <el-submenu index="3">
          <template slot="title">政策法规</template>
          <el-menu-item @click="jumpHandler('rules')" index="3-1">法律法规</el-menu-item>
          <el-menu-item @click="jumpHandler('policyFile')" index="3-2">政策文件</el-menu-item>
          <el-menu-item @click="jumpHandler('unscramble')" index="3-3">政策解读</el-menu-item>
        </el-submenu>
         <el-submenu index="2">
          <template slot="title">统计查询</template>
          <el-menu-item @click="jumpHandler('workDynamic')" index="2-1">立项查询</el-menu-item>
          <el-menu-item @click="jumpHandler('training')" index="2-2">标准查询</el-menu-item>
        </el-submenu>
        <el-menu-item @click="jumpHandler('download')" index="4">下载中心</el-menu-item>
        <el-menu-item @click="jumpHandler('contactUs')" index="6">联系我们</el-menu-item>
      </el-menu>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isNotice: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      text: "",
      activeIndex: "0", //
      loginUrl: window.loginUrl,
      registerUrl: window.registerUrl
    }
  },
  methods: {
    jumpHandler(path, query = {}) {
      if (this.$route.name !== path || this.$route.name === 'organization') this.$router.push({ name: path, query })
    },

    jumpMapHandler() {
      this.$router.push({ name: 'map' })
    },

    searchHandler() {

      if (!this.text) {
        return;
      }

      if (this.text !== this.$route.query.search) this.$router.push({ name: "search", query: { search: this.text }})
    }
  }
}
</script>

<style scoped>

.header {
  width: 100%;
  height: 1.65rem;
  background: url("../common/images/top_bg.png");
  background-size: 100% 100%;
  min-width: 960px;
  display: flex;
  align-items:center;
  justify-content: center;
}

.headerTab {
  width: 100%;
  min-width: 960px;
  height: .45rem;
  background: url("../common/images/tab_bg.png");
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.header-box {
  width: 14rem;
  height: 100%;
  display: flex;
  align-items:center;
  justify-content: center;
}

.header-l {
  width: 50%;
  color: #fff;
  display:flex;
  flex-wrap: wrap;
  align-items: center;
}

.header-l h3 {
  font-size: 0.38rem;
  font-weight: 700;
  width: 100%;
}

.header-l p {
  height: 0.16rem;
  font-size: 0.16rem;
  line-height: 0.16rem;
  font-weight: bold;
}


.header-r {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: .15rem;
  height: 100%;
  box-sizing: border-box;
}

.header-login {
  font-size: .16rem;
  font-weight: 400;
  color: #FFFFFE;
  margin-bottom: 0.45rem;
}

.header-login a {
  font-size: .16rem;
  font-weight: 400;
  color: #FFFFFE;
}

.header-search {
  width: 1.92rem;
  position: relative;
  height: 0.37rem;
  display: flex;
}

.header-input {
  width: 1.42rem;
  height: 0.37rem;
}

.header-search >span {
  font-size: 0.14rem;
  font-weight: bold;
  color: #FFFFFF;
  position:absolute;
  right:0;
  top:0;
  background: #0071B7;
  width: .5rem;
  height: 100%;
  text-align:center;
  line-height: .37rem;
  border-radius: 0 .18rem .18rem 0;
  cursor:pointer;
}

.header img {
  width: 100%;
  height: 100%;
}

</style>

<style>
.header-input input {
  height: 100% !important;
  line-height: 0.37rem;
  font-size: 0.12rem;
  border-radius: .18rem 0 0 .18rem;
}

.header-menu {
  width: 14rem;
  height: 100%;
  min-width: 960px;
  margin: 0 auto;
}

.header-menu .el-menu-item, .header-menu .el-submenu__title {
  line-height: .45rem !important;
  height: 100% !important;
  border: none !important;
}

.header-menu .el-menu-item:hover, .header-menu .el-submenu__title:hover,
.el-menu-item:hover, .header-menu .is-active {
  background: #007ECA !important;
}

.header-menu .el-submenu__icon-arrow::before {
  content: " ";
}

.el-submenu, .el-menu-item {
  width: 1.75rem;
  text-align: center;
}

.el-menu-item {
   font-size: .20rem !important;
}

.el-submenu__title {
  font-size: .20rem !important;
}

.el-menu--popup {
  background: #0071B7 !important;
  width: 1.75rem !important;
  min-width: 1.5rem !important;
}

.el-menu--popup .el-menu-item {
  font-size: .18rem !important;
}

</style>