<template>
  <div class="sidebar">
    <el-menu
      :default-active="activeIndex"
      class="sidebar-menu"
      background-color="#D2EFFF"
      text-color="#000000"
      ref="sidebarRef"
      active-text-color="#fff"
      :default-openeds="defaultOpeneds"
    >
      <el-menu-item @click="jumpHandler('home')" class="sidebar-First" index="0"
        >首页</el-menu-item
      >
      <el-menu-item @click="jumpHandler('news')" index="10"
        >新闻动态</el-menu-item
      >
      <el-submenu @click.native="jumpHandler1" index="1">
        <template slot="title"
          ><span class="triangle-right"></span>组织机构</template
        >
        <el-menu-item
          @click.native.stop="jumpHandler('organization', { tag: 'first' })"
          index="1-1"
          >热标委介绍</el-menu-item
        >
        <el-menu-item
          @click.native.stop="jumpHandler('organization', { tag: 'second' })"
          index="1-2"
          >热标委章程</el-menu-item
        >
        <el-menu-item
          @click.native.stop="jumpHandler('organization', { tag: 'third' })"
          index="1-3"
          >热标委组成</el-menu-item
        >
        <el-menu-item
          @click.native.stop="jumpHandler('organization', { tag: 'fourth' })"
          index="1-4"
          >联系我们</el-menu-item
        >
        <el-menu-item
          @click.native.stop="jumpHandler('organization', { tag: 'fourth1' })"
          index="1-5"
          >其他平台</el-menu-item
        >
      </el-submenu>
      <el-menu-item @click="jumpHandler('notice')" index="7"
        >通知公告</el-menu-item
      >
      <el-menu-item @click="jumpHandler('opinion')" index="8"
        >征求意见</el-menu-item
      >
      <el-submenu @click.native="jumpHandler2" index="2">
        <template slot="title"
          ><span class="triangle-right"></span>政策法规</template
        >
        <el-menu-item @click.native.stop="jumpHandler('rules')" index="2-1"
          >法律法规</el-menu-item
        >
        <el-menu-item @click.native.stop="jumpHandler('policyFile')" index="2-2"
          >政策文件</el-menu-item
        >
        <el-menu-item @click.native.stop="jumpHandler('unscramble')" index="2-3"
          >政策解读</el-menu-item
        >
      </el-submenu>
      <el-submenu @click.native="jumpHandler11" index="11">
        <template slot="title"
          ><span class="triangle-right"></span>发布公告</template
        >
        <el-menu-item @click.native.stop="jumpHandler('bulletin')" index="11-2"
          >国际标准</el-menu-item
        >
        <el-menu-item @click.native.stop="jumpHandler('nationalStandard')" index="11-1"
          >国家标准</el-menu-item
        >
        <el-menu-item @click.native.stop="jumpHandler('industryStandard')" index="11-3"
          >行业标准</el-menu-item
        >
        <el-menu-item @click.native.stop="jumpHandler('localStandards')" index="11-4"
          >地方标准</el-menu-item
        >
        <el-menu-item @click.native.stop="jumpHandler('groupStandards')" index="11-5"
          >团体标准</el-menu-item
        >
      </el-submenu>
      <el-menu-item @click="jumpHandler('standard')" index="13"
        >标准解读</el-menu-item
      >
      <el-submenu @click.native="jumpHandler3" index="3">
        <template slot="title"
          ><span class="triangle-right"></span>统计查询</template
        >
        <el-menu-item
          @click.native.stop="jumpHandler('workDynamic')"
          index="3-1"
          >立项查询</el-menu-item
        >
        <el-menu-item @click.native.stop="jumpHandler('training')" index="3-2"
          >标准查询</el-menu-item
        >
      </el-submenu>
      <el-menu-item @click="jumpHandler('download')" index="4"
        >下载中心</el-menu-item
      >
      <el-menu-item @click="jumpHandler('contactUs')" index="6"
        >联系我们</el-menu-item
      >
    </el-menu>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      activeIndex: "0", //
      defaultOpeneds: [],
    }
  },
  mounted() {
    let arr = this.$route.meta.tag.split("-")

    if (this.$route.name === "organization") {
      if (this.$route.query.tag === "first") {
        this.activeIndex = "1-1"
      } else if (this.$route.query.tag === "second") {
        this.activeIndex = "1-2"
      } else if (this.$route.query.tag === "third") {
        this.activeIndex = "1-3"
      } else if (this.$route.query.tag === "fourth") {
        this.activeIndex = "1-4"
      } else if (this.$route.query.tag === "fourth1") {
        this.activeIndex = "1-5"
      }
    } else {
      this.activeIndex = this.$route.meta.tag
    }
    if (arr.length) {
      this.defaultOpeneds = [arr[0]]
    }
  },
  methods: {
    jumpHandler(path, query) {
      if (this.$route.name !== path || this.$route.name === "organization")
        this.$router.push({ name: path, query })
    },

    jumpHandler1() {
      if (
        this.activeIndex == "1-1" ||
        this.activeIndex == "1-2" ||
        this.activeIndex == "1-3" ||
        this.activeIndex == "1-4" ||
        this.activeIndex == "1-5"
      ) {
        return
      }
      this.jumpHandler("organization", { tag: "first" })
    },

    jumpHandler2() {
      if (
        this.activeIndex == "2-1" ||
        this.activeIndex == "2-2" ||
        this.activeIndex == "2-3"
      ) {
        return
      }
      this.jumpHandler("rules")
    },

    jumpHandler11() {
      if (
        this.activeIndex == "11-1" ||
        this.activeIndex == "11-2" ||
        this.activeIndex == "11-3" || 
        this.activeIndex == "11-4" || 
        this.activeIndex == "11-5"
      ) {
        return
      }
      this.jumpHandler("bulletin")
    },

    jumpHandler3() {
      if (this.activeIndex == "3-1" || this.activeIndex == "3-2") {
        return
      }
      this.jumpHandler("workDynamic")
    },

    switchHandler(tag) {
      if (tag === "first") {
        this.activeIndex = "1-1"
      } else if (tag === "second") {
        this.activeIndex = "1-2"
      } else if (tag === "third") {
        this.activeIndex = "1-3"
      } else if (tag === "fourth") {
        this.activeIndex = "1-4"
      } else if (tag === "fourth1") {
        this.activeIndex = "1-5"
      }
    },
  },
}
</script>

<style scoped>
.sidebar {
  height: 100%;
  overflow-y: auto;
  margin-bottom: 1rem;
}
.sidebar-menu {
  background: #d2efff;
  width: 3.08rem;
}

.triangle-right {
  width: 0;
  height: 0;
  border-top: 0.04rem solid transparent;
  border-left: 0.06rem solid #000000;
  border-bottom: 0.04rem solid transparent;
  display: inline-block;
  margin-right: 0.2rem;
  position: absolute;
  left: 0.16rem;
  top: 42%;
}
</style>

<style>
.is-opened .triangle-right {
  border-left: 0.04rem solid transparent !important;
  border-right: 0.06rem solid transparent !important;
  border-top: 0.04rem solid #000 !important;
}

.sidebar-menu .el-menu-item:hover,
.sidebar-menu .el-submenu__title:hover,
.el-menu-item:hover,
.sidebar-menu .is-active {
  background: #007eca !important;
  color: #fff !important;
}

.sidebar-First {
  background: #007eca !important;
  color: #fff !important;
  font-size: 0.25rem !important;
  font-weight: 400 !important;
}

.sidebar-menu .el-menu-item,
.sidebar-menu .el-submenu {
  width: 100% !important;
  font-size: 0.2rem !important;
  font-weight: 400 !important;
}

.sidebar-menu .el-submenu__title {
  width: 100% !important;
  font-size: 0.2rem !important;
  font-weight: 400 !important;
}

.sidebar-menu .el-menu-item,
.sidebar-menu .el-submenu__title {
  text-align: left;
  padding-left: 0.3rem !important;
  min-height: 0.56rem;
  display: flex;
  align-items: center;
}

.sidebar-menu .el-submenu__icon-arrow::before {
  content: " ";
}

.sidebar-menu .is-opened .triangle-right {
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 6px solid transparent;
  border-top: 4px solid #000;
  top: 26px;
}

.sidebar-menu .el-menu--inline .el-menu-item {
  font-size: 0.18rem !important;
  text-indent: 15px;
}
</style>
